import React from "react";
import { GrWorkshop, GrContactInfo } from "react-icons/gr";
import { AiOutlineUser, AiFillCar } from "react-icons/ai";
import { CgAdd } from "react-icons/cg";

// import { BsFillHouseFill } from "react-icons/bs";
// import { CiMoneyCheck1 } from "react-icons/ci";
// import { FaNetworkWired } from "react-icons/fa";

const Menus = [
  { title: "Backoffice", path: "/backoffice", icon: <GrWorkshop /> },
  { title: "Clases", path: "/backoffice/clases", icon: <AiOutlineUser /> },
  { title: "Profesores", path: "/backoffice/profesores", icon: <CgAdd />}
//   {
//     title: "Talleres",
//     path: "/backoffice/workshops",
//     icon: <BsFillHouseFill />
//   },
//   {
//     title: "Transacciones",
//     path: "/backoffice/transacciones",
//     icon: <AiFillCar />
//   },
//   {
//     title: "Trabajadores",
//     path: "/backoffice/trabajadores",
//     icon: <AiOutlineUser />
//   },
//   {
//     title: "Contactanos",
//     path: "/backoffice/contactanos",
//     icon: <GrContactInfo />
//   },
  // {
  //   title: "Roles en talleres",
  //   path: "/backoffice/workshopRoles",
  //   icon: <FaNetworkWired />
  // },
//   {
//     title: "Planes de suscripción",
//     path: "/backoffice/SuscriptionPlans",
//     icon: <CiMoneyCheck1 />
//   }
];

export default Menus;
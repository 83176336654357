import React, { useState, useEffect } from 'react';
import '../styles/Card.css';
import anime from 'animejs';
import { Link } from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';

const Card = ({ data }) => {
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState(data);
  // Filtrando los datos
  
  let cardAnimation = () => {
    anime({
      targets: '.Card',
      translateX: [-2000, 0],
      direction: 'normal',
      easing: 'linear',
      delay: 300,
      opacity: [0, 1],
    });
  };

  useEffect(() => {
    cardAnimation();
  }, []);

  useEffect(() => {
    setFilteredData(
      data.filter(
        (item) =>
          item.title.toLowerCase().includes(search.toLowerCase()) ||
          item.content.toLowerCase().includes(search.toLowerCase()),
      ),
    );
  }, [search, data]);
  const completeCourses = filteredData.filter(item => item.course === "complete");
  const interrogationCourses = filteredData.filter(item => item.course === "I");

  return (
    <div className="CardContainer">
      
      <div className="classTitle">
        <p>
          Accede a nuestro catálogo de cursos
          
        </p>
        <a href="https://seremonta.store/catalog" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="button-link">
              Haz click aquí
          </a> 
      </div>

      <div className="classDescription">
        Aprende desde la comodidad de tu casa, cuando quieras y las veces que quieras
      </div>
      <div className="classDefinition">
        <h2> REMONTA EL CURSO (curso completo) </h2>
      </div>
    
      <div className="Card">
        
        {completeCourses.map((item, index) => (
          <div className="box" key={index}>
            {/* <h3>{item.title}</h3> */}
            <p>{item.content}</p>
            <a href={`${item.link}`} className="btn" target="_blank" rel="noopener noreferrer">
              Saber más
            </a>
          </div>
        ))}
      </div>

      <div className="classDefinition">
        <h2> CURSOS PARA PREPARAR LA I1  </h2>
      </div>
      <div className="Card">
        
        {interrogationCourses.map((item, index) => (
          <div className="box" key={index}>
            {/* <h3>{item.title}</h3> */}
            <p>{item.content}</p>
            <a href={`${item.link}`} className="btn" target="_blank" rel="noopener noreferrer">
              Saber más
            </a>
          </div>
        ))}
      </div>


    </div>
  );
};

export default Card;

import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import About from '../components/About';
import Home from '../components/Home';
import Profile from '../profile/profileDes';
import TakeClass from './pages/Class';
import ContactUs from './pages/ContactUs';
import CourseDetail from './Design/CourseDetail';
import NotFound from './Design/404';
import BackOffice from '../pages/backoffice';
import ClassBackOffice from '../pages/backoffice/classBackOffice';
import TeachersBackOffice from '../pages/backoffice/teacherBackOffice';
import FrequentQuestions from './pages/frequentQuestions';
import ReservedClass from '../pages/reservedClass/reservedClass';
import { useSelector } from "react-redux";
import ScrollToTop from './effectsInPage/scrollToTop';
function Routing() {
  const userStore = useSelector((state) => state.user);
  const { user, isLoading } = useAuth0();


  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path={'/'} element={<Home />} />
          <Route path={'/about'} element={<About />} />
          <Route path={'/profile'} element={<Profile />} />
          <Route path={'/takeClass'} element={<TakeClass />} />
          <Route path={'/contactUs'} element={<ContactUs />} />
          <Route path={'/questions'} element={<FrequentQuestions />} />
          <Route path="/courses/:courseId" element={<CourseDetail />} />
          <Route path={'/reservedClasses'} element={<ReservedClass />} />
          <Route path="*" element={<NotFound />} />
          {userStore.isAdmin && (
            <>
            <Route path="/backoffice" element={<BackOffice />} />
            <Route path="/backoffice/clases" element={<ClassBackOffice/>} />
            <Route path="/backoffice/profesores" element={<TeachersBackOffice />} />
            </>
          )}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default Routing;

import React from 'react';
import Navbar from '../Design/Navbar';
import Footer from '../../components/Design/Footer';
import { useAuth0 } from '@auth0/auth0-react';
import PlansSeRemonta from '../inc/plans';
import { Navigate } from 'react-router-dom';
import swal from 'sweetalert2';
function ContactUs() {

    const { user, isAuthenticated, isLoading } = useAuth0();
    if (isLoading) {
        return <div>Loading ...</div>;
    }
    if (!isAuthenticated) {
        swal.fire({
          title: 'Debes iniciar sesión',
          text: 'No Puedes solicitar un plan personalizado si no estas logueado',
          icon: 'warning',
        });
        return <Navigate to="/" />;
      }
    return (
        <div>
            {isAuthenticated ? (
                <>
                <Navbar />

                <div className="take-class-container">
                    <PlansSeRemonta></PlansSeRemonta>
                    
                </div>

                <Footer />
                </>
            ) : (
                //else
                <div>
                <h1>No Puedes tomar clases si no estas logueado</h1>
                {/* Puedes agregar aquí cualquier otro contenido que desees mostrar */}
                </div>
            )}
        </div>
    );
}


export default ContactUs;
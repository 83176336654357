import React from 'react';
import { Table } from 'react-bootstrap';
import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ChangeFormatDate from '../../components/Collections/ChangeFormatDate';
import "../dataTableStyles/dataTablePaidClass.css";
const DataTable = ({ data }) => {
    const [show, setShow] = useState(false);
    const [selectedClass, setSelectedClass] = useState(null);
    const handleShow = (item) => {
        setSelectedClass(item);
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setSelectedClass(null);
    };
    return (
        <>
            <div className="card-container">
            {data.map((item, index) => (
                <div key={index} className="class-card" onClick={() => handleShow(item)}>
                    <h3>{item.ramo} ({item.sigla})</h3>
                    <p>{ChangeFormatDate(item.classDate)} {item.classStart}</p>
                    <Button variant="primary">Ver más</Button>
                </div>
            ))}

            {/* Modal */}
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Detalles de la Clase</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedClass && (
                        <>
                            <p><strong>Ramo:</strong> {selectedClass.ramo}</p>
                            <p><strong>Sigla:</strong> {selectedClass.sigla}</p>
                            <p><strong>Fecha:</strong> {ChangeFormatDate(selectedClass.classDate)}</p>
                            <p><strong>Inicio:</strong> {selectedClass.classStart}</p>
                            <p><strong>Fin:</strong> {selectedClass.classEnd}</p>
                            <p><strong>Modalidad:</strong> {selectedClass.modality}</p>
                            <p><strong>Universidad:</strong> {selectedClass.university}</p>
                            <p><strong>Estado:</strong> {selectedClass.status}</p>
                            <p><strong>Profesor:</strong> {selectedClass.name}</p>
                            <p><strong>Precio:</strong> {selectedClass.price}</p>
                            <p><strong>Contacto:</strong> {selectedClass.contact}</p>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
        </>
    );
};

export default DataTable;
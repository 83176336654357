import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Routing from './components/Routing';
import { Auth0Provider } from '@auth0/auth0-react';
import store from "./app/store";

// import 'bootstrap-icons/font/bootstrap-icons.css';

import { Provider } from "react-redux";
import App from './App';
const root = ReactDOM.createRoot(document.getElementById('rootapp'));
const callbackUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;
root.render(
  <Provider store={store}>
    <Auth0Provider 
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        audience:process.env.REACT_APP_AUTH0_AUDIENCE,
        redirect_uri:callbackUri,
      }}
      
      scope="read:current_user"
      
    > 
      <App/>   {/* Al agregar App acá se crea un duplicado del footer */}
      {/* <React.StrictMode>
        <Routing/>
      </React.StrictMode> */}
    </Auth0Provider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

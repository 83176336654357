// creamos un compoenente que será un grafico de lineas que recibe los data de antes, y reflejará un flujo, debe ser suave y con el área puntada
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineController,
  LinearScale,
  PointElement,
  LineElement,
  CategoryScale,
  Legend,
  Tooltip
} from "chart.js";

ChartJS.register(
  LineController,
  LinearScale,
  PointElement,
  LineElement,
  CategoryScale,
  Legend,
  Tooltip
);

function LineChart({ data, textTitle }) {
  const [charData, setCharData] = useState({
    datasets: []
  });
  const [charOptions, setCharOptions] = useState({});
  useEffect(() => {
    setCharData(data);
    setCharOptions({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top"
        },
        title: {
          display: true,
          text: textTitle
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      }
    });
  }, [data, textTitle]);

  return <Line data={charData} options={charOptions} />;
}

export default LineChart;
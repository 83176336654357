import React from "react";

export default function Pagination({
  totalPages,
  currentPage,
  handlePageChange
}) {
  return (
    <div className="w-full flex mt-4 items-center justify-center">
      {Array.from({ length: totalPages }).map((_, index) => (
        <button
          type="button"
          key={`page-${index + 1}`}
          onClick={() => handlePageChange(index + 1)}
          className={`px-3 py-2 mx-2 rounded-lg ${
            currentPage === index + 1 ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
        >
          {index + 1}
        </button>
      ))}
    </div>
  );
}

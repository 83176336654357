import React from 'react';
import Navbar from './Design/Navbar';
import Footer from './Design/Footer';
import Card from './Design/Card';
import jsonData from './data.json';
import HomePage from './Design/HomePage';
import HomeSlider from './Design/Slider';
import Milestones from './Design/Milestones';
import EngagementSection from './Design/EngagementSection';
import styled from 'styled-components';


// Ensure full-width layout with responsive behavior
const FullWidthSection = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal overflow */
`;

const GradientBackground = styled.div`
  background: linear-gradient(180deg, #E0F7FA, #0B96C9, #1A547C);
`;

const WaveTop = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
`;

const WaveBottom = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  line-height: 0;
`;

const WaveSVG = styled.svg`
  position: relative;
  display: block;
  width: 100%; /* Responsive width */
  height: 100px;

  @media (min-width: 768px) {
    height: 150px; /* Adjust height for larger screens */
  }
`;

const Section = styled.section`
  padding: 40px 20px;

  @media (min-width: 768px) {
    padding: 80px 40px; /* More padding on larger screens */
  }
`;

function Home() {
  return (
    <div>
      <Navbar />
      <FullWidthSection>
        <HomeSlider />
      </FullWidthSection>

      <GradientBackground>
        <WaveBottom>
          <WaveSVG
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.4c67.92,3.22,115.2,28,184.64,31.3,72.73,3.53,132.37-22.41,198.33-31.3,110.19-14.8,219,18.47,303,40V0H0V44.41C67.76,29.92,253.18,53.1,321.39,56.4Z"
              className="shape-fill"
              fill="#E0F7FA"
            />
          </WaveSVG>
        </WaveBottom>

        <Section>
          <HomePage />
        </Section>

        <WaveTop>
          <WaveSVG
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.4c67.92,3.22,115.2,28,184.64,31.3,72.73,3.53,132.37-22.41,198.33-31.3,110.19-14.8,219,18.47,303,40V0H0V44.41C67.76,29.92,253.18,53.1,321.39,56.4Z"
              className="shape-fill"
              fill="#0B96C9"
            />
          </WaveSVG>
        </WaveTop>

        <Section>
          <Card data={jsonData} />
        </Section>
      
        <Section>
          <Milestones />
        </Section>
        <Section>
          <EngagementSection />
        </Section>

      </GradientBackground>
      

      <Footer />
    </div>
  );
}

export default Home;

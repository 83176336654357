import React from "react";

export default function EmptyState({ message, title }) {
  return (
    <div className="w-full flex flex-col p-10 bg-white rounded-r-xl rounded-b-xl rounded-l-xl h-[90%] m-2 border border-sh-gray border-dashed overflow-hidden">
      <div className="flex flex-col justify-center items-center">
        <div className="flex flex-col justify-center items-center">
          <h1 className="text-3xl font-lexend font-bold">{title}</h1>
          <p className="text-xl font-lexend">{message}</p>
        </div>
      </div>
    </div>
  );
}

import React, { lazy, Suspense } from 'react';
import styled from 'styled-components';
import Loading from './Loading';
import GIF from '../GIF/GIFHomePage.gif'; // Asegúrate de que la ruta al GIF sea correcta
import CoverVideo from './CoverVideo'; // Importa el componente CoverVideo

const TypeWriterText = lazy(() => import('./TypeWriterText'));

const Section = styled.section`
  min-height: ${(props) => `calc(100vh - ${props.theme.navHeight})`};
  width: 100%;
  position: relative;
  background: linear-gradient(135deg, #E0F7FA 0%, #1A547C 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  border-radius: 40px;
  overflow: hidden;

  @media (max-width: 90em) {
    max-height: 140vh;
  }
`;

const Container = styled.div`
  width: 75%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 64em) {
    width: 85%;
    flex-direction: column;
  }

  @media (max-width: 48em) {
    width: 95%;
    flex-direction: column;
  }
`;

const TextBox = styled.div`
  flex: 1;
  margin-right: 2rem;
  color: #ffffff;
  text-align: left;
  padding: 20px;
  border-radius: 20px;

  @media (max-width: 64em) {
    margin-right: 0;
    margin-bottom: 2rem;
  }
`;

const VideoBox = styled.div`
  flex: 1;
  max-width: 700px;
  height: 700px;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
  background-color: #1A547C;

  @media (max-width: 64em) {
    max-width: 100%;
    height: auto;
  }

  @media (max-width: 48em) {
    max-width: 100%;
    height: 350px;
  }
`;

const HomePage = () => {
  return (
    <Section id="home">
      <Container>
        <TextBox>
          <Suspense fallback={<Loading />}>
            <TypeWriterText />
          </Suspense>
        </TextBox>
        <VideoBox>
          <Suspense fallback={<Loading />}>
            <CoverVideo gifSrc={GIF} /> {/* Pasar el GIF como prop */}
          </Suspense>
        </VideoBox>
      </Container>
    </Section>
  );
};

export default HomePage;

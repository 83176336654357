import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './styles/CoursesModal.css';

function CoursesModal() {
  const [show, setShow] = useState(false);

  // Mostrar el modal automáticamente cuando se carga la página
  useEffect(() => {
    setShow(true);
  }, []);

  const handleClose = () => setShow(false);
  const handleRedirect = () => {
    window.location.href = "https://seremonta.store/catalog";
  };

  return (
    <Modal 
      show={show} 
      onHide={handleClose} 
      centered  /* Centra el modal */
      size="lg" /* Tamaño grande para pantallas más grandes */
      className="responsive-modal" /* Clase CSS para personalizarlo */
    >
      <Modal.Header closeButton>
        <Modal.Title className="custom-modal-title"> ¡Tenemos nuevos cursos online para ti!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        ¿Quieres ver si te gustan antes de decidir? Mira un video de <span className="highlight">muestra gratis</span> y conoce cómo funcionan nuestras clases. ¡Estudia desde tu casa, mejora tus notas y avanza a tu ritmo!
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={handleRedirect}>
          Ver Cursos
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
        
      </Modal.Footer>
    </Modal>
  );
}

export default CoursesModal;


import React from 'react';
import Navbar from '../Design/Navbar';

import '../styles/404.css';
const NotFound = () => {
  return (
    <>
      <Navbar />
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <h1>Oops!</h1>
          </div>
          <h2>404 - Page not found</h2>
          <p>
            The page you are looking for might have been removed had its name
            changed or is temporarily unavailable.
          </p>
          <a href="/">Go To Homepage</a>
        </div>
      </div>
    </>
  );
};

// 4. Exportar el componente
export default NotFound;

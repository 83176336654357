import getApiUrl from "../config/urlConfig.js";
import { callExternalApi } from "./externalApiService";

export const getProtected = async (route, accessToken) => {
  const config = {
    url: `${getApiUrl()}${route}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  };

  const { data, error, response } = await callExternalApi(config, false);

  return {
    data: data || null,
    error,
    response
  };
};

export const deleteProtected = async (route, accessToken) => {
  const config = {
    url: `${getApiUrl()}${route}`,
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  };

  const { data, error } = await callExternalApi(config, false);

  return {
    data: data || null,
    error
  };
};

export const deleteProtectedWithBody = async (route, body, accessToken) => {
  const config = {
    url: `${getApiUrl()}${route}`,
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    data: body
  };

  const { data, error } = await callExternalApi(config, false);

  return {
    data: data || null,
    error
  };
};

export const postProtected = async (route, body, accessToken) => {
  const config = {
    url: `${getApiUrl()}${route}`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    data: body
  };

  const { data, error } = await callExternalApi(config, false);

  return {
    data: data || null,
    error
  };
};

export const patchProtected = async (route, body, accessToken) => {
  const config = {
    url: `${getApiUrl()}${route}`,
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    data: body
  };

  const { data, error } = await callExternalApi(config, false);

  return {
    data: data || null,
    error
  };
};

export const getProtectedRaw = async (route, accessToken) => {
  const config = {
    url: `${getApiUrl()}${route}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  };

  const { response, error } = await callExternalApi(config, true);

  return {
    response: response || null,
    error
  };
};

export const postProtectedRaw = async (route, body, accessToken) => {
  const config = {
    url: `${getApiUrl()}${route}`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    data: body
  };

  const { response, error } = await callExternalApi(config, true);

  return {
    response: response || null,
    error
  };
};

export const patchProtectedRaw = async (route, body, accessToken) => {
  const config = {
    url: `${getApiUrl()}${route}`,
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    data: body
  };

  const { response, error } = await callExternalApi(config, true);

  return {
    response: response || null,
    error
  };
};

import React, { useState, useEffect } from "react";
import { AiTwotoneEdit } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { format } from "date-fns";
import spinner from "../../../assets/gifs/spinner.gif";
import { getProtected } from "../../../services/queries";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";

export default function TableAssigment({
    handleEdit,
    handleDelete,
    currentAssignments
  }) {
    const [idEdit, setIdEdit] = useState(null);
    const [idDelete, setIdDelete] = useState(null);
    const [teachersData, setTeachersData] = useState([]);
    const [associatedCurrentAssignmentsTeachers, setAssociatedCurrentAssignmentsTeachers] = useState([]);
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
      const getDataTeacher = async(id) => {
        try {
            const accessToken = await getAccessTokenSilently();
            const response = await getProtected(`/teachers/${id}`, accessToken)
            if (response && response.error) {
                toast.error(response.error.message);
            } else {
                return response.data;
            }
        } catch (error) {
            toast.error(error.message);
        }
    }
    
    if (currentAssignments) {
      const fetchTeachersAndAssignments = currentAssignments.map(async (assignment) => {
        if (assignment.teacherId) {
          const teacher = await getDataTeacher(assignment.teacherId);
          return [assignment, teacher];
        } else {
          return [assignment, null];
        }
      });
  
      Promise.all(fetchTeachersAndAssignments)
        .then(associatedCurrentAssignmentsTeachers => {
          setAssociatedCurrentAssignmentsTeachers(associatedCurrentAssignmentsTeachers);
        });
    }

      
    }, [currentAssignments, getAccessTokenSilently]);

    const formatDate = (value) => {
      const date = new Date(value);
      return format(date, "dd/MM/yyyy HH:mm:ss");
    };
    const getStatusColor = (status) => {
      switch (status.toLowerCase()) {
        case 'reservada':
          return 'text-green-500 font-bold';
        case 'asignada':
          return 'text-yellow-500 font-bold';
        case 'finalizada':
          return 'text-red-500 font-bold';
        default:
          return '';
      }

    
    }

  
    return (
      <div className="overflow-auto space-y-4">
        <hr />
        <ul className="space-y-4 no-scrollbar p-5">
          {associatedCurrentAssignmentsTeachers.map(([assignment, teacher], index) => (
            <li
              className="border-gray-400 flex flex-col hover:bg-gray-200 rounded-lg shadow-lg group"
              key={assignment.id}
            >
              <div className="bg-sh-lightblue h-1 w-full opacity-25 group-hover:opacity-100" />
              <div className="w-full text-left p-4 hover:bg-gray-50">
                <h1 className="text-gray-900 font-bold">
                  CLASE DE {assignment.ramo.toUpperCase()} ({assignment.sigla.toUpperCase()}) PARA {assignment.name.toUpperCase()} {assignment.lastName.toUpperCase()} 
                </h1>
                <hr />
                <div className="flex flex-row justify-between">
                  <div className="flex flex-col">
                    <p className={`px-2 ${getStatusColor(assignment.status)}`}>Estado: {assignment.status.toUpperCase()}</p>
                    <p className="px-2"> Nombre del ramo: {assignment.ramo.toUpperCase()}  </p>
                    <p className="px-2"> Sigla del ramo: {assignment.sigla.toUpperCase()}  </p>
                    <p className="px-2"> Fecha de la clase: {formatDate(assignment.classDate)}</p>
                    <p className="px-2"> Hora inicio clase: {assignment.classStart}</p>
                    <p className="px-2"> Hora fin clase: {assignment.classEnd}</p>
                    <p className="px-2"> Profesor: {teacher ? teacher.name : "POR FIJAR"}</p>
                    <p className="px-2"> Precio: {teacher ? teacher.price : "POR FIJAR"}</p>
                    <p className="px-2"> Número de contacto profesor: {teacher ? teacher.contact : "POR FIJAR"}</p>
                  </div>
                  <div className="text-center inline-flex gap-5 items-center">
                    {idEdit && assignment.id === idEdit ? (
                      <img
                        src={spinner}
                        alt="spinner"
                        className="bg-white"
                        width="30px"
                        height="30px"
                      />
                    ) : (
                      <button
                        type="button"
                        className="w-full flex items-center justify-center hover:bg-transparent"
                        onClick={async (e) => {
                          setIdEdit(assignment.id);
                          await handleEdit(e, assignment);
                          setIdEdit(null);
                        }}
                      >
                        <AiTwotoneEdit
                          size={28}
                          color="#60ebe1"
                          className="opacity-50 hover:opacity-100"
                        />
                      </button>
                    )}
                    {idDelete && assignment.id === idDelete ? (
                      <img
                        src={spinner}
                        alt="spinner"
                        className="bg-white"
                        width="30px"
                        height="30px"
                      />
                    ) : (
                      <button
                        type="button"
                        className="w-full flex items-center justify-center text-red-600 hover:bg-transparent"
                        onClick={(e) => {
                          setIdDelete(assignment.id);
                          handleDelete(e, assignment.id);
                        }}
                      >
                        <RiDeleteBin6Line
                          size={28}
                          color="red"
                          className="opacity-50 hover:opacity-100"
                        />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }
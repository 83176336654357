import React, { useState, useEffect, useCallback } from "react";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import { AiOutlineInfoCircle } from "react-icons/ai";
import MoreInfo from "../../shared/moreinfo";
import { getProtected, deleteProtected } from "../../../services/queries"
import EmptyState from "../../shared/emptyState";
import { useNavigate } from "react-router-dom";
import PaginatedAssigment from "./paginatedAssigment";
export default function TransactionClass() {
    const { getAccessTokenSilently } = useAuth0();
    const [dataTable, setDataTable] = useState([]);

    const getClasses = useCallback(async () => {
        try {
            const accessToken = await getAccessTokenSilently();
            const response = await getProtected(
                "/currentClass", accessToken
            )
            setDataTable(response.data)
        } catch (err){
            toast.error(err.message)
        }
    }, [getAccessTokenSilently]);

    useEffect( () => {
        getClasses();
    }, [getClasses] )

    const handleDelete = async (e, id) => {
        e.preventDefault();
        try {
            const accessToken = await getAccessTokenSilently();
            const response = await deleteProtected(`/currentClass/${id}`, accessToken)
            if (response && response.error) {
                toast.error(response.error.message);
            } else {
                toast.success("Clase eliminada con éxito");
                await getClasses();
            }
        } catch(error) {
            toast.error(error.message);
        }
    }


    return (
        <div className="bg-white flex-col rounded-l-xl rounded-r-xl w-full p-10 h-[80vh]">
            <div className="inline-flex gap-2 items-center">
                <h1 className="text-2xl font-lexend font-bold text-black">
                Asignaciones
                </h1>
                <button
                    className="btn btn-sm btn-circle btn-ghost opacity-50 hover:opacity-100 hover:bg-transparent"
                    type="button"
                    onClick={() => {
                        window.infoClassDescription.showModal();
                    }}
                    >
                    <AiOutlineInfoCircle
                        size={28}
                        className="text-2xl opacity-50 hover:opacity-100 hover:bg-transparent"
                    />
                </button>

                <MoreInfo id="infoClassDescription">
                    <div className="flex flex-col space-y-5">
                        <p className="text-black font-lexend text-xl">
                        Clases y asignaciones
                        </p>
                        <p className="text-black font-lexend text-sm">
                        En esta sección se muestran las clases y se pueden editar las asignaciones
                        de clases (profesores, horas) seleccionadas. Las clases 
                        se pueden editar tanto en fecha, horario de inicio y fin, sin embargo
                        los datos del alumno no pueden ser editados. Por otra parte se puede asignar profesores
                        o cambiar cierto profe, asimismo se puede eliminar una clase .
                        </p>
                    </div>
                </MoreInfo>
            </div>


        
        {dataTable.length ===0 && (
            <EmptyState
                message="No hay registro de clases asignadas"
                title="Clases asignadas"
            
            />
        )}
        {dataTable.length > 0 && (
            <PaginatedAssigment
                assignments={dataTable}
                handleDelete={handleDelete}
                reload={getClasses}
            />
        )}
        </div>

    )
}

import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { getProtected } from "../../services/queries";
import Loading from "../../components/loading/loading";
import {
    SelectedTab,
    WaitingTab
  } from "../../components/transactions/components/tabs";
import TransactionTabContainer from "../../components/transactions/tabContendShowContainer";
import Navbar from "../../components/Design/Navbar";
import Footer from "../../components/Design/Footer";
import DataTable from "./dataTablePaidClass";
import DataTableReserved from "./dataTableReservedClass";
import "../reservedClassDesign/reservedClass.css";


function ReservedClass(){
    const { getAccessTokenSilently } = useAuth0();
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [currentRole, setCurrentRole] = useState({});
    const [actualUser, setActualUser] = useState(null);
    const [dataClassesStudentStatusPaid, setDataClassesStudentStatusPaid] = useState(null);
    const [dataClassesStudentStatusReserved, setDataClassesStudentStatusReserved] = useState(null);
    const [dataClassesTeacher, setDataClassesTeacher] = useState(null);
    const [dataComplete, setDataComplete] = useState(null);
    const dispatch = useDispatch();
    const { user} = useAuth0();
    const getActualUser = async () => {
        try{
          const accessToken = await getAccessTokenSilently();
          const { data } = await getProtected('/users/me', accessToken);
          return data;
          
        }
        catch(error){
          return false;
        }
      }

      const getDataClassesStudentStatusPaid = async (userId) => {
        try {
            const accessToken = await getAccessTokenSilently();
            const response = await getProtected(`/currentClass/status/paid/${userId}`, accessToken);
            if (response && response.error) {
                toast.error(response.error.message);
            } else {
                return response.data;
            }
        } catch (error) {
            toast.error(error.message);
        }
    }
    const getDataClassesStudentStatusReserved = async (userId) => {
        try {
            const accessToken = await getAccessTokenSilently();
            const response = await getProtected(`/currentClass/status/reserved/${userId}`, accessToken);
            if (response && response.error) {
                toast.error(response.error.message);
            } else {
                return response.data;
            }
        } catch (error) {
            toast.error(error.message);
        }
    }
    const getDataTeacher = async(id) => {
        try {
            const accessToken = await getAccessTokenSilently();
            const response = await getProtected(`/teachers/${id}`, accessToken)
            if (response && response.error) {
                toast.error(response.error.message);
            } else {
                return response.data;
            }
        } catch (error) {
            toast.error(error.message);
        }
    }
    
    useEffect(() => {
        const fetchActualUser = async () => {
            const user = await getActualUser();
            setActualUser(user);
            if (user) {
                const dataStudent = await getDataClassesStudentStatusPaid(user.id);
                setDataClassesStudentStatusPaid(dataStudent);
                const dataStudentReserve = await getDataClassesStudentStatusReserved(user.id);
                if (dataStudentReserve && dataStudentReserve[0]){
                    setDataClassesStudentStatusReserved(dataStudentReserve);
                   
                }
                if (dataStudent && dataStudent[0] && dataStudent[0].teacherId){
                    const dataTeacher = await getDataTeacher(dataStudent[0].teacherId)
                    setDataClassesTeacher(dataTeacher);
                    setDataComplete(dataStudent.map(item => ({ ...item, ...dataTeacher })));
                }
                
            }
        };
    
        fetchActualUser();
    }, []);





    
    // const getStudentClassPaid = async () => {
    //     try {
    //         const accessToken = await getAccessTokenSilently();
    //         const response = await getProtected(`/users/${}`)
            

    //     }
    //     catch (error){

    //     }
    // }
    // tabs handler
    const [currentTab, setCurrentTab] = useState(0);
    // const data = [
    //     { columna1: 'Dato 1', columna2: 'Dato 2' },
    //     { columna1: 'Dato 3', columna2: 'Dato 4' },
    //     // ...
    // ];
    const tabContents = [
        <div className="flex flex-col transition-all ease-in-out duration-75">
            {dataClassesStudentStatusReserved && <DataTableReserved data={dataClassesStudentStatusReserved} />}
        </div>,
        <div className="flex flex-col transition-all ease-in-out duration-75">
            {dataComplete && <DataTable data={dataComplete} />}
        </div>,
        // <div className="flex flex-col transition-all ease-in-out duration-75">
        //     <p>Tareas y Asignaciones</p>
        // </div>,
        // <div className="flex flex-col transition-all ease-in-out duration-75">
        //     <p>Gastos y Presupuesto total</p>
        // </div>
    ];




    // if (isLoading) {
    //     return <Loading />;
    // }

    return (
        <>
            <Navbar />
            <div className="container mx-auto p-5">
                <div className="tab-container">
                    <button 
                        className={`tab-button ${currentTab === 0 ? 'selected' : ''}`} 
                        onClick={() => setCurrentTab(0)}
                    >
                        Clases Reservadas
                    </button>
                    <button 
                        className={`tab-button ${currentTab === 1 ? 'selected' : ''}`} 
                        onClick={() => setCurrentTab(1)}
                    >
                        Clases Pagadas
                    </button>
                </div>

                {currentTab === 0 && dataClassesStudentStatusReserved && <DataTableReserved data={dataClassesStudentStatusReserved} />}
                {currentTab === 1 && dataClassesStudentStatusPaid && <DataTable data={dataClassesStudentStatusPaid} />}
            </div>
            <Footer />
        </>
    )
  }

export default ReservedClass;
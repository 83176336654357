import { useState, useEffect } from 'react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import '../styles/HomeSlider.css';
// import required modules
import '../styles/Slider.css';
import image2 from '../images/Image1 (2).jpg';
import image3 from '../images/Image1 (3).jpg';
import image4 from '../images/Image1 (4).jpg';
import { Link } from 'react-router-dom';



export default function HomeSlider() {
  const [currImage, setCurrImage] = useState(0); 

  const images = [
    { src: image2, alt: 'Description for Image 2', title: 'SeRemonta', subtitle: '¿Preparado para remontar tu semestre?' },
    { src: image3, alt: 'Description for Image 3', title: 'SeRemonta', subtitle: 'Presentamos profesores de las mejores universidades' },
    { src: image4, alt: 'Description for Image 4', title: 'SeRemonta', subtitle: '¡Salva tu semestre con nuestra ayuda!' },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrImage(currImage === images.length - 1 ? 0 : currImage + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, [currImage, images.length]);

  return (
    <div className='home-slider__container'>
      <h1>{images[currImage].title}</h1>
      
      <p>{images[currImage].subtitle}</p>
      <figure>
        <img src={images[currImage].src} alt={images[currImage].alt} style={{animation: 'fadeIn 1s ease-in-out'}} />
      </figure>

      <button>Remonta tu semestre</button>
      <Link to="/takeClass" id="edit_profile" className="seremonta-btn">
        Remonta tu semestre
      </Link>
    </div>
  )
}

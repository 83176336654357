import React, { useState } from "react";
import { GoSidebarCollapse } from "react-icons/go";
import { FaTools } from "react-icons/fa";
import { Link } from "react-router-dom";

function SideNavbarDashboard({ title, data }) {
  const [isOpen, setIsOpen] = useState(false);
  const Menus = data;

  return (
    <div
      className={`${isOpen ? "w-70" : "w-0"} duration-300 relative  h-[100vh]`}
    >
      <GoSidebarCollapse
        onClick={() => setIsOpen(!isOpen)}
        color="sh-darkblue"
        className={`cursor-pointer text-2xl bg-purple-200 rounded-md duration-300  hover:text-sh-darkblue absolute -right-6 mt-1 ${
          isOpen && "transform rotate-180"
        }`}
      />
      <div className="inline-flex mt-7">
        <h1
          className={`text-sh-darkblue text-center ${
            !isOpen && "scale-0"
          } duration-300 text-xl origin-left`}
        >
          {title}
        </h1>
      </div>

      <div className="flex flex-col rounded-md bg-light-white justify-between">
        {Menus.map((item) => (
          <Link
            key={item.title}
            to={item.path}
            className={`flex mt-6 rounded-md bg-light-white hover:ml-2 hover:text-light-white hover:shadow-lg hover:scale-105 duration-200 ${
              !isOpen && "w-4 h-4 hidden"
            }`}
          >
            <span className="block float-left text-2xl w-4 h-4">
              {item.icon ? (
                item.icon
              ) : (
                <FaTools className="text-sh-darkblue w-full h-full fill-current" />
              )}
            </span>
            <h1
              className={`text-sh-darkblue ml-2 block float-left cursor-pointer hover:text-xl ${
                !isOpen && "scale-0"
              }`}
              hidden={!isOpen}
            >
              {item.title}
            </h1>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default SideNavbarDashboard;

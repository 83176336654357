/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
import { createSlice } from "@reduxjs/toolkit";

const initialUserState = {
  email: "",
  email_verified: true,
  family_name: "",
  given_name: "",
  locale: "",
  name: "",
  nickname: "",
  picture: "",
  sub: "",
  updated_at: "",
  id: "",
  isAdmin: false
};

export const userSlice = createSlice({
  name: "user",
  initialState: { ...initialUserState, loading: false },
  reducers: {
    setUser: (state, action) => {
      const {
        email,
        email_verified,
        family_name,
        given_name,
        locale,
        name,
        nickname,
        picture,
        sub,
        updated_at,
        isAdmin,
        id
      } = action.payload;
      state.loading = true;
      state.email = email;
      state.email_verified = email_verified;
      state.family_name = family_name;
      state.given_name = given_name;
      state.locale = locale;
      state.name = name;
      state.nickname = nickname;
      state.picture = picture;
      state.sub = sub;
      state.updated_at = updated_at;
      state.isAdmin = isAdmin;
      state.loading = false;
      state.id = id;
    },
    setIsAdmin: (state, action) => {
      state.isAdmin = action.payload;
    }
  }
});

export const { setUser, setIsAdmin } =
  userSlice.actions;
export default userSlice.reducer;

import React from 'react';
import '../styles/EngagementSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import SeRemontaEngagement from '../GIF/SeRemontaEngagement.gif';
import { Link } from 'react-router-dom';
const EngagementSection = () => {
    return (
        <div className="engagement-container">
            <h2 className="engagement-title">Optimiza tu tiempo y maximiza tu rendimiento</h2>
            <p className="engagement-description">
                En SeRemonta, estamos comprometidos en ayudarte a alcanzar tus metas académicas. 
                Ofrecemos clases personalizadas, material de estudio detallado y soporte constante 
                para que puedas aprender a tu ritmo y remontar tu semestre.
            </p>
            <div className="engagement-gif">
                <img src={SeRemontaEngagement} alt="Estudiantes colaborando" />
            </div>
            <div className="engagement-benefits">
                <div className="benefit-card">
                    <FontAwesomeIcon icon={faCheckCircle} className="icon-tick" />
                    <p>Mejora tus resultados</p>
                </div>
                <div className="benefit-card">
                    <FontAwesomeIcon icon={faCheckCircle} className="icon-tick" />
                    <p>Aprende en fácil y a tu ritmo</p>
                </div>
                <div className="benefit-card">
                    <FontAwesomeIcon icon={faCheckCircle} className="icon-tick" />
                    <p>Estudia de manera inteligente</p>
                </div>
                
                <Link to="/takeClass" className="remonta-button-link">
                    <button className="remonta-button">
                        ¡Remonta este semestre!
                    </button>
                </Link>
                
            </div>
        </div>
    );
};

export default EngagementSection;
import React from 'react';
import '../styles/Milestones.css'; // Asegúrate de crear este archivo CSS o ajustarlo según donde lo tengas


const Milestones = () => {
    return (
        <div className="milestones-container">
            <h2 className="milestones-title">Juntos remontamos el semestre</h2>
            <div className="milestones-grid">
                <div className="milestone-card animate__animated animate__fadeInUp">
                    <h3 className="milestone-number">500+</h3>
                    <p className="milestone-description">Clases realizadas por medio de seremonta</p>
                </div>
                <div className="milestone-card animate__animated animate__fadeInUp animate__delay-1s">
                    <h3 className="milestone-number">400+</h3>
                    <p className="milestone-description">Remontadas realizadas</p>
                </div>
                <div className="milestone-card animate__animated animate__fadeInUp animate__delay-2s">
                    <h3 className="milestone-number">15+</h3>
                    <p className="milestone-description">Profesores expertos</p>
                </div>
                <div className="milestone-card animate__animated animate__fadeInUp animate__delay-3s">
                    <h3 className="milestone-number">30+</h3>
                    <p className="milestone-description">Ramos realizados</p>
                </div>
            </div>
        </div>
    );
};

export default Milestones;

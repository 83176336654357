import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "react-bootstrap";

const LogoutButton = () => {
  const { logout } = useAuth0();
  const callbackUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;

  return (
    <Button variant="info" onClick={() => logout({ returnTo: callbackUri })}>
      Cerrar Sesión
    </Button>
  );
};

export default LogoutButton;
import React, { useState } from "react";

export default function SectionClass({ selected, handleChange }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="w-full flex flex-col gap-2">
            <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="w-full flex flex-col justify-start p-2"
            >
            
            <div className="mt-3 h-1 w-full bg-yellow-500 rounded-lg opacity-50" />
            <h1 className="text-black text-xl font-normal mt-1" hidden={isOpen}>
                Clase de {selected.sigla} ({selected.ramo}) para{selected.name} !!!
            </h1>
            </button>
            
            <div className={`${isOpen ? "h-full" : "h-0"} duration-300 w-full`}>
                {isOpen && (
                    <>
                        <div className="w-full justify-start items-end inline-flex text-black text-xl font-normal bg-transparent bg-opacity-20 rounded-lg">
                            <input
                                placeholder="titulo de la tarea"
                                value={selected.sigla.toUpperCase()}
                                name="title"
                                hidden={!isOpen}
                                onChange={(e) => console.log(e)}
                                className="w-full text-black text-lg  font-semibold hover:border rounded-lg p-2 hover:border-gray-700 bg-yellow-500 bg-opacity-20 focus:bg-yellow-500 focus:bg-opacity-50"
                            />
                        </div>
                        <hr />

                    
                        <div className="w-full flex-col justify-start items-start flex gap-4 mt-4">
                            <div className="w-full flex-col justify-start items-start flex">
                                <span hidden={!isOpen}>Estado de la tarea: </span>
                                {isOpen && (
                                <select
                                    placeholder="estado"
                                    name="status"
                                    hidden={!isOpen}
                                    onChange={(e) => handleChange(e)}
                                    className="w-full p-3 bg-white rounded-lg shadow border border-gray-200 border-opacity-40 flex-col justify-center items-start flex"
                                >
                                    <option
                                    value=""
                                    className="text-gray-500"
                                    selected
                                    disabled
                                    hidden
                                    >
                                    {selected.status}
                                    </option>
                                    <option className="w-full" value="Reservada">
                                        Reservada
                                    </option>
                                    <option className="w-full" value="Asignada">
                                        Asignada
                                    </option>
                                    <option className="w-full" value="Pagada">
                                        Pagada
                                    </option>
                                    <option className="w-full" value="Realizada">
                                        Realizada
                                    </option>
                                </select>
                                )}
                            </div>
                        <div className="gap-9 grid grid-cols-5 w-full items-center">
                            <div className="w-full col-span-2">
                                <span hidden={!isOpen}>Hora inicio: </span>
                                <input
                                    type="number"
                                    name="hourInit"
                                    value={selected.hourInit}
                                    placeholder="(formato: hh:mm)"
                                    hidden={!isOpen}
                                    onChange={(e) => handleChange(e)}
                                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
                                />
                            </div>
                            <div className="w-full col-span-2">
                                <span hidden={!isOpen}>Hora fin: </span>
                                <input
                                    type="number"
                                    name="hourfinin"
                                    value={selected.hourFin}
                                    placeholder="(formato: hh:mm)"
                                    hidden={!isOpen}
                                    onChange={(e) => handleChange(e)}
                                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
                                />
                            </div>
                            <div className="col-span-3">
                                <span hidden={!isOpen}>Fecha de la clase: </span>
                                <input
                                    type="date"
                                    value={
                                        selected.deadline
                                        ? new Date(selected.deadline).toLocaleDateString(
                                            "en-CA"
                                            )
                                        : ""
                                    }
                                    name="deadline"
                                    hidden={!isOpen}
                                    onChange={(e) => handleChange(e)}
                                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
                                    
                                />
                            </div>

                        </div>

                        </div>
                    </>
                )

                }


            </div>

        </div>
    )
}
import React, { useEffect, useState } from 'react';
import { BiMenuAltRight } from 'react-icons/bi';
import Nav from 'react-bootstrap/Nav';
import { AiOutlineClose } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import '../styles/navbar.scss';
import LoginButton from '../../profile/Login';
import LogoutButton from '../../profile/Logout';
import { useAuth0 } from '@auth0/auth0-react';
import { getProtected } from '../../services/queries';
import { toast } from "react-toastify";
import Logo from '../../assets/logo/SeRemontaLogo.png'; 

function Navbar() {
  const { isAuthenticated, user } = useAuth0();
  const [menuOpen, setMenuOpen] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [backendUser, setBackendUser] = useState(null);
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const fetchUser = async () => {
      if (isAuthenticated) {
        try {
          const accessToken = await getAccessTokenSilently();
          const response = await getProtected(`/users/email/${user.email}`, accessToken);
          if (response && response.error) {
            toast.error(response.error.message);
          } else {
            setBackendUser(response.data);
          }
        } catch (error) {
          toast.error(error.message);
        }
      }
    };

    fetchUser();
  }, [isAuthenticated, user, getAccessTokenSilently]);

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (size.width > 960 && menuOpen) {
      setMenuOpen(false);
    }
  }, [size.width, menuOpen]);

  const menuToggleHandler = () => {
    setMenuOpen((p) => !p);
  };

  return (
    <header className="header">
      <div className="header__content">
        <Link to="/" className="header__content__logo">
          <img src={Logo} alt="SeRemonta Logo" className="header__logo-img" />
          <span>SeRemonta</span>
        </Link>
        <nav
          className={`${'header__content__nav'} 
          ${menuOpen && size.width < 960 ? `${'isMenu'}` : ''} 
          }`}
        >
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            {isAuthenticated && (
              <li>
                <Link to="/profile">Perfil</Link>
              </li>
            )}
            {isAuthenticated && backendUser && backendUser.isAdmin === true && (
              <li>
                <Link to="/backoffice/clases">Admin clases</Link>
              </li>
            )}
            {isAuthenticated && backendUser && backendUser.isAdmin === true && (
              <li>
                <Link to="/backoffice/profesores">Admin crear profesor</Link>
              </li>
            )}
            <li className="nav-item">
              <a href="https://seremonta.store" target="_blank" rel="noopener noreferrer" className="nav-link">
                Cursos Online
                <span className="new-label">¡Nuevo!</span>
              </a>
            </li>

            <li>
              <Link to="/takeClass">Pedir clases 💙</Link>
            </li>
            <li>
              <Link to="/contactUs">Solicitar plan</Link>
            </li>
            {isAuthenticated ? (
              <Nav.Link href="/">
                <LogoutButton />
              </Nav.Link>
            ) : (
              <Link>
                <LoginButton />
              </Link>
            )}
          </ul>
        </nav>
        <div className="header__content__toggle">
          {!menuOpen ? (
            <BiMenuAltRight onClick={menuToggleHandler} />
          ) : (
            <AiOutlineClose onClick={menuToggleHandler} />
          )}
        </div>
      </div>
    </header>
  );
}

export default Navbar;

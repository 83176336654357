/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { AiOutlineUser, AiFillCar } from "react-icons/ai";
import { FaWarehouse } from "react-icons/fa";
import AmountCardEntity from "./AmountCardEntity";
import { getProtected } from "../../services/queries";

export default function ResumeBackoffice() {
  const { getAccessTokenSilently } = useAuth0();
  const [resumeAmount, setResumeAmount] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const usersResponse = await getProtected("/users", accessToken);
        const classesResponse = await getProtected("/currentClass", accessToken);
        // const transactionsResponse = await getProtected(
        //   "/transactions",
        //   accessToken
        // );
        setResumeAmount([
          {
            title: "Usuarios",
            amount: usersResponse.data.length,
            icon: (
              <AiOutlineUser className="text-sh-darkblue w-full h-full fill-current" />
            ),
            description:
              "Usuarios registrados en la plataforma. Son la totalidad de usuarios que se han registrado en la plataforma. Pueden tener diferentes roles dentro de SeRemonta (estudiante, profesor, admin)."
          },
          {
            title: "Clases",
            amount: classesResponse.data.length,
            icon: (
              <FaWarehouse className="text-sh-darkblue w-full h-full fill-current" />
            ),
            description:
              "Clases registrados en la plataforma. Las clases son la representación de la cantidad total de clases reservadas en la página."
          },
        //   {
        //     title: "Transacciones",
        //     amount: transactionsResponse.data.length,
        //     icon: (
        //       <AiFillCar className="text-sh-darkblue w-full h-full fill-current" />
        //     ),
        //     description:
        //       "Transacciones realizadas en la plataforma. Las transacciones corresponden a los autos atendidos dentro de un taller. Por lo que equivale a cuantas veces se han atentido autos en la plataforma."
        //   }
        ]);
      } catch (error) {
        /* Error */
      }
    };
    getData();
  }, [getAccessTokenSilently]);
  return (
    <div className="flex flex-col w-full m-auto lg:flex-row justify-between space-x-0.5 space-y-0.5">
      {resumeAmount.map((item) => (
        <AmountCardEntity
          key={item.title}
          // title={item.title}
          amount={item.amount}
          icon={item.icon}
          description={item.description}
        />
      ))}
      
    </div>
  );
}

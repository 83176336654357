import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Navbar from "../../components/Design/Navbar";
import Footer from "../../components/Design/Footer";
import Loading from "../../components/Design/Loading";
import ContainerPage from "../../components/BackOffice/layouts/ContainerPage";
import { postProtected } from "../../services/queries";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
export default function TeachersBackOffice() {
    const { getAccessTokenSilently } = useAuth0();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const initialTeacherData = {
        isAdmin: true,   // Se agrega el campo isAdmin, CAMBIAR DESPUES
        personalId: '',
        name: '',
        price: '',
        contact: '',
        email: '',
        age: '',
        career: ''
    };

    const [teacherData, setTeacherData] = useState(initialTeacherData);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setTeacherData({ ...teacherData, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        Swal.fire({
            title: '¿Estás seguro?',
            text: "¡Crearás un nuevo profesor!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, crear profesor!',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true);
                try {
                    const accessToken = await getAccessTokenSilently();
                    const response = await postProtected('/teachers', teacherData, accessToken);
                    Swal.fire('¡Creado!', 'El profesor ha sido creado exitosamente.', 'success');
                    setTeacherData(initialTeacherData); // Resetear el formulario
                    navigate(`/backoffice/profesores`, { replace: true });
                } catch (error) {
                    Swal.fire('Error', 'No se pudo crear el profesor: ' + error.message, 'error');
                    setError(error);
                } finally {
                    setLoading(false);
                }
            }
        });
    };

    return (
        <>
            <Navbar />
            <ContainerPage>
                {loading ? (
                    <Loading />
                ) : (
                    <form className="flex flex-col p-6 m-auto bg-white border border-gray-200 rounded-lg shadow-lg w-full lg:w-1/2 space-y-4" onSubmit={handleSubmit}>
                        <h2 className="text-xl font-bold mb-4">Crear Profesor</h2>
                        {error && <p className="text-red-500">{error.message}</p>}
                        {['personalId', 'name', 'price', 'contact', 'email', 'age', 'career'].map((field, index) => (
                            <div key={index} className="flex flex-col">
                                <label className="mb-1 capitalize">{field.replace(/([A-Z])/g, ' $1')}</label>
                                <input type={field === 'email' ? 'email' : (field === 'price' || field === 'age') ? 'number' : 'text'}
                                       name={field}
                                       value={teacherData[field]}
                                       onChange={handleInputChange}
                                       required
                                       className="p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                />
                            </div>
                        ))}
                        <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Crear Profesor</button>
                    </form>
                )}
            </ContainerPage>
            <Footer />
        </>
    );
}

import React from 'react';
import '../styles/footer.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faHouse, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="footer-col">
            <h4>SeRemonta</h4>
            <div className="text-container">
              <div className="text">
              Se Remonta conecta a los mejores profesores de las principales universidades de Chile con estudiantes de todo el país. Ofrecemos clases particulares y cursos online para mejorar el rendimiento académico en colegio, preparar la PAES, y superar los ramos más difíciles de la educación superior. Con un enfoque personalizado, ayudamos a nuestros alumnos a alcanzar sus metas académicas con el apoyo de docentes expertos.
              </div>
            </div>
          </div>
          <div className="footer-col">
            <h4>¿Alguna duda?</h4>
            <ul>
              <li>
                <Link to="/questions">Preguntas frecuentes</Link>
              </li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>Síguenos</h4>
            <div className="social-links">
              <a href="https://www.instagram.com/seremonta.cl/">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>
          </div>
          <div className="footer-col">
            <h4>Contáctanos</h4>
            <p><FontAwesomeIcon icon={faHouse} /> Av Vicuña Mackenna 4860, Chile</p>
            <p><FontAwesomeIcon icon={faEnvelope} /> seremonta.cl@gmail.com</p>
            <p><FontAwesomeIcon icon={faPhone} /> +56 9 3573 7202</p>
          </div>
        </div>
      </div>
      <div className="text-center p-3">
        Hecho con 💙 por el equipo de SeRemonta: <a className="text-blue" href="https://www.instagram.com/seremonta.cl/">Equipo</a>
      </div>
      <div className="text-center p-4">
        
        <p>© 2024 SeRemonta</p>
          
        
      </div>
    </footer>
  );
}

export default Footer;

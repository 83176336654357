import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
import swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import Navbar from '../components/Design/Navbar';
import Footer from '../components/Design/Footer';
import imageBackground from '../components/images/fondo_profile.png';
import '../components/styles/profileDes.css';

const Profile = () => {
  const { user, isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    swal.fire({
      title: 'Debes iniciar sesión',
      text: 'No puedes ver tu perfil si no estás logueado',
      background: '#fff',
      icon: 'warning',
    });
    return <Navigate to="/" />;
  }

  return (
    isAuthenticated && (
      <>
        <Navbar />

        <main className='profile-container'>
          {/* <h1>Mi Perfil</h1> */}
          <section className='profile-data'>
            <figure className='user-picture'>
              <img src={user.picture} alt={user.name} className='user-picture' />
              <caption>{user.name}</caption>
            </figure>
          </section>

          <div className='divider'></div>

          <section className='profile-info'>
            <h2>Información Personal</h2>
            <div className='info'>
              <p><span className='bolder dark'>Nombre</span>: <span className='bolder-white'>{user.name}</span></p>
              <p><span className='bolder dark'>Email</span>: <span className='bolder-white'>{user.email}</span></p>
              <p><span className='bolder dark'>Usuario</span>: <span className='bolder-white'>{user.nickname}</span></p>
            </div>
          </section>

          <div className='divider'></div>
          <h2>Detalles</h2>
          <section className='profile-actions'>
            <article>
              <p>Clases reservadas</p>
              <Link to='/reservedClasses' className="btn btn-danger btn-block">
                Mis clases reservadas
              </Link>
            </article>

            {/* <article>
              <p>Cambiar contraseña</p>
              <Link to='/change-password' className="btn btn-primary btn-block">
                Cambiar contraseña
              </Link>
            </article>
            <article>
              <p>Editar mi perfil</p>
              <Link to="/edit-profile" id="edit_profile" className="btn btn-primary btn-block">
                Editar Perfil
              </Link>
            </article> */}
          </section>
        </main>
        <Footer />
      </>
    )
  );
};

export default Profile;

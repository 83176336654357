import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Footer from './components/Design/Footer';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { setUser } from './features/user/userSlice';
import { getProtected } from "./services/queries";
import Routing from './components/Routing';
import Loading from "./components/loading/loading";
import CoursesModal from "./components/Modals/CoursesModal";
function App() {
  const { user, isLoading } = useAuth0();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const userStore = useSelector((state) => state.user);
  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const { data, response } = await getProtected("/users/me", accessToken);
        if (response?.status === 503) {
          setLoading(false);
        } else {
          const {
            id,
            isActive,
            isAdmin,
            isPremium,
            isVerified,
            personalId,
            phone,
            premiumExpiration,
            type
          } = data;
          const { given_name, family_name, email, name, picture, sub, locale } =
            user;
          const dispatchPromises = [
            dispatch(
              setUser({
                id,
                isActive,
                isAdmin,
                isPremium,
                isVerified,
                personalId,
                phone,
                premiumExpiration,
                type,
                given_name,
                family_name,
                email,
                name,
                picture,
                sub,
                locale,
                ...data
              })
            ),
            // dispatch(setIsAdmin(data?.isAdmin ?? false))
          ];
          await Promise.all(dispatchPromises);
        }
      } catch (error) {
        // Manejar el error de autenticación o solicitud aquí
      }
    };

    fetchData().then(() => {
      setLoading(false);
    });
  }, [dispatch, getAccessTokenSilently, isLoading, user]);
  if (isLoading || loading) {
    return <Loading />;
  }

  return (
    <div>
      <CoursesModal />

      {/* <Navbar /> */}
      <React.StrictMode>
        <Routing/>
      </React.StrictMode>
      
      


      {/* <Footer /> */}
    </div>
  );
}

export default App;




import React, { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ContainerPage from "../../components/BackOffice/layouts/ContainerPage";
import ResumeBackoffice from "../../components/BackOffice/ResumenCardIndex";
import LineChart from "../../components/BackOffice/LineChart";
import Navbar from "../../components/Design/Navbar";
import Footer from "../../components/Design/Footer";
import { getProtected } from "../../services/queries";
import ResumeClassBackoffice from "../../components/BackOffice/ResumenCardClass";
import Loading from "../../components/Design/Loading";
import TransactionClass from "../../components/BackOffice/class/transactionClass";


export default function ClassBackOffice() {
    const { getAccessTokenSilently } = useAuth0();
    const [dataCards, setDataCards] = useState([]);
    const [dataLineChart, setDataLineChart] = useState({});
    const [loading, setLoading] = useState(true);
    const [dataClass, setDataClass] = useState([]);
    const [error, setError] = useState(null);

    const getData = useCallback(async () => {
        try {
            const accessToken = await getAccessTokenSilently();
            const clases = await getProtected("/currentClass", accessToken);
            const clasesPorMes = clases.data.reduce((acc, clase) => {
                const month = new Date(clase.createdAt).getMonth();
                acc[month] = acc[month] ? acc[month] + 1 : 1;
                return acc;
            }, {});
            const months = [
                "ene",
                "feb",
                "mar",
                "abr",
                "may",
                "jun",
                "jul",
                "ago",
                "sep",
                "oct",
                "nov",
                "dic"
            ];
            const clasesPorMesArray = months.map((month) => {
                const index = months.indexOf(month);
                return clasesPorMes[index] || 0;
            });
            setDataLineChart({
                labels: months,
                datasets: [
                    {
                        label: "clases",
                        data: clasesPorMesArray,
                        backgroundColor: "rgba(255, 99, 132, 0.2)",
                        borderColor: "rgba(255, 99, 132, 1)",
                        pointBorderColor: "rgba(255, 99, 132, 1)",
                        borderWidth: 1,
                        fill: true,
                        tension: 0.4
                    }
                ]
            });
            setDataClass(clases.data);
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    }, [getAccessTokenSilently]);
    useEffect(() => {
        getData();
      }, [getData]);
    return (
        <>
            <Navbar />
                <ContainerPage>
                { loading ? (
                    <Loading />
                ): (   
                    <div className="flex flex-col justify-between space-x-2 space-y-2">
                        <ResumeClassBackoffice />
                        <div className="border-gray-200 shadow hover:bg-gray-100 w-full lg:h-[70vh] h-[50vh] m-auto p-4 border rounded-lg bg-white">
                            { dataLineChart && (
                            <LineChart
                                data={dataLineChart} // Pasar datos vacíos ya que no estamos haciendo ninguna llamada de red
                                textTitle="Nuevos usuarios por mes"
                            />
                            )}
                        </div>
                        <div className="border-gray-200 shadow hover:bg-gray-100 w-full lg:h-[70vh] h-[50vh] m-auto p-4 border rounded-lg bg-white">
                            <TransactionClass/>
                            
                        </div>
                    </div>
                )}
                </ContainerPage>
            <Footer />
        </>
    );
}
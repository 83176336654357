/* eslint-disable */
import React, { useRef } from "react";

function Modal({ id, children, handleSubmit, className = "" }) {
  const dialogRef = useRef(null);

  const handleClose = () => {
    if (dialogRef.current) {
      dialogRef.current.close();
    }
  };

  return (
    <dialog id={id} className={`modal-box ${className}`} ref={dialogRef}>
      <form
        // method="dialog"
        className=""
        onSubmit={(e) => {
          handleSubmit(e);
          handleClose();
        }}
      >
        <button
          type="button"
          htmlFor={id}
          className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
          onClick={handleClose}
        >
          ✕
        </button>
        {children}
      </form>
    </dialog>
  );
}

export default Modal;

import { useParams, Navigate } from 'react-router-dom';
import courseData from '../courseData.json';
import swal from 'sweetalert2';
import Navbar from '../Design/Navbar';
import '../styles/course.css';

const CourseDetail = () => {
  const { courseId } = useParams();

  const course = courseData.find((c) => c.id === courseId);

  if (!course) {
    swal.fire({
      title: 'Curso no encontrado',
      icon: 'warning',
    });
    return <Navigate to="/" />;
  }

  return (
    <>
      <Navbar />
      <div className="container-course-detail">
        <h2>{course.name}</h2>
        <p>{course.description}</p>
        <h3>Detalle del curso</h3>
        <p>Precio por hora: ${course.pricePerHour}</p>
        <p>Total de horas: {course.totalHours}</p>
        <h3>Contenidos del curso</h3>
        <ul>
          {course.courseContent.map((content, index) => (
            <li key={index}>
              <strong>{content.type}:</strong> {content.title} -{' '}
              {content.duration}
            </li>
          ))}
        </ul>
        {/* <div className="teacher-card">
          <h3>Docente</h3>
          <img src={course.teacher.profileImage} alt="Docente" width="100" />
          <p>{course.teacher.name}</p>
          <p>Formación: {course.teacher.qualification}</p>
          <p>Experiencia: {course.teacher.experience}</p>
          <p>Motivación: {course.teacher.motivation}</p>
          <p>Cursos dictados: {course.teacher.coursesTaught}</p>
          <p>Estudiantes totales: {course.teacher.totalStudents}</p>
          <p>Calificación: {course.teacher.rating} estrellas</p>
          <p>Total de opiniones: {course.teacher.totalReviews}</p>
        </div> */}
      </div>
    </>
  );
};

export default CourseDetail;

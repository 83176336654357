import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import  { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import { RiDeleteBin6Line } from "react-icons/ri";
import {
    getProtected,
    postProtected,
    deleteProtected,
    patchProtected
} from "../../../services/queries"
import spinner from "../../../assets/gifs/spinner.gif";
import Select from 'react-select';
// Hay que tomar en consideracion que el handleAddTeacher y el handleDeleteTeacher no eliminan la fila en sí, solo cambian la columan teacherId
// handleAddTeacher cambia el teacherId a un valor uuid() y handleDeleteTeacher lo cambia a null
// para ambos casos, se ocupa el patchProtected

export default function SectionTeacher({ selectedAssigment }) {
    const { getAccessTokenSilently } = useAuth0();
    // const { id } = useParams();
    const [teachers, setTeachers] = useState([]);
    const [assignedTeachers, setAssignedTeachers] = useState([]);
    const [newTeacherToAssign, setNewTeacherToAssign] = useState(null);
    const [loadingPlus, setLoadingPlus] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [idDelete, setIdDelete] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    
    
    



    const getTeachers = useCallback(async () => {
        try {
            const accessToken = await getAccessTokenSilently();
            const responseTeachers = await getProtected(
                `/teachers`,
                accessToken
            );
            setTeachers(responseTeachers.data);
        } catch (error) {
            toast.error("No se pudieron obtener los profesores")
        }

    }, [getAccessTokenSilently]);


    const getAssignedTeachers = useCallback(async () => {
        try {
            const accessToken = await getAccessTokenSilently();
            const responseAssignedTeacher = await getProtected(
                `/currentClass/${selectedAssigment.id}/teacher`,
                accessToken
            );
            setAssignedTeachers(responseAssignedTeacher.data);
        } catch (error) {
            toast.error("No se pudieron obtener los profesores asignados")

        }
    }, [getAccessTokenSilently, selectedAssigment.id]);

    useEffect( () => {
        getTeachers();
        getAssignedTeachers();
    }, [getTeachers, getAssignedTeachers, selectedAssigment.id]);


    const handleAddTeacher = async (e) => {
        e.preventDefault();
        try{
            setLoadingPlus(true);
            if (newTeacherToAssign === null) {
                // toast.error("Debe seleccionar un profesor");
                return;
            }
            // if ( assignedTeachers.find((teacher) => teacher.id === newTeacherToAssign.id)) {
            //     return;
            // }
            const body = {
                teacherId: newTeacherToAssign,
            };
            const accessToken = await getAccessTokenSilently();
            const responseCreate = await patchProtected(
                `/currentClass/teacherPatch/${selectedAssigment.id}`,
                body,
                accessToken
            );

            if (responseCreate && responseCreate.error) {
                toast.error("No se pudo asignar el profesor");
            } else {
                toast.success("Profesor asignado con éxito");
                await getAssignedTeachers();
                setNewTeacherToAssign(null);
                setLoadingPlus(false);
            }

        } catch(error){
            toast.error("No se pudo asignar el profesor");
        }
    };

    const handleDeleteTeacher = async (e) => {
        e.preventDefault();
        try {
            setLoadingDelete(true);
            const accessToken = await getAccessTokenSilently();
            const response = await patchProtected(
                `/currentClass/${selectedAssigment.id}`,
                { teacherId: null },
                accessToken
            );
            if (response && response.error) {
                toast.error("No se pudo eliminar el profesor");
            } else {
                toast.success("Profesor eliminado con éxito");
                await getAssignedTeachers();
                setLoadingDelete(false);
            }
        } catch(error) {
            toast.error("No se pudo eliminar el profesor");
        }
    }

    return (
        <div className="flex-col justify-between items-start mt-2 flex gap-4 w-full">
            <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="w-full flex flex-col justify-start p-2"
            >
                <div className="mt-3 h-1 w-full bg-green-500 rounded-lg opacity-50" />
                <h1 className="text-black text-xl font-normal mt-1">Asignaciones Profesor</h1>

            </button>

            <div className={`${isOpen ? "h-full" : "h-0"} duration-300 w-full`}>
                <div className="grid grid-cols-3 w-full gap-2">
                    {isOpen && (
                        <select
                            hidden={!isOpen}
                            onChange={(e) => {setNewTeacherToAssign(e.target.value)}}
                            className="w-full p-3 bg-white rounded-lg shadow border border-gray-200 border-opacity-40 flex-col justify-center items-start flex col-span-2 text-green"
                        >
                        <option className="text-gray-700 w-full" value={null} hidden>
                            Selecciona un profesor
                        </option>
                        {teachers.map((teacher) => {
                            // si contiene el id del worker en el array de trabajadores asignados
                            // if (
                            // assignedTeachers.find(
                            //     (assignedTeacher) => assignedTeacher.userId === teacher.userId
                            // )
                            // ) {
                            //     return null;
                            // }
                            return (
                                <option key={teacher.id} value={teacher.id}>
                                    {teacher.name}
                                </option>
                            // fixear, colocar un console.log(teacher) para saber los atributos
                            );
                                
                        })}

                        </select>
                    )}
                    {/* ahora hacemos un boton para confirmar */}
                    <div className="items-center flex justify-center">
                        {loadingPlus ? (
                        <img
                            src={spinner}
                            alt="spinner"
                            className="bg-white col-span-1"
                            width="30px"
                            height="30px"
                            hidden={!isOpen}
                        />
                        ) : (
                        <button
                            disabled={newTeacherToAssign === null}
                            type="button"
                            hidden={!isOpen}
                            onClick={(e) => handleAddTeacher(e)}
                            className={`bg-green-500 text-white rounded-lg p-2 hover:bg-green-600 ${
                            newTeacherToAssign === null && "opacity-50 cursor-not-allowed"
                            }`}
                        >
                            Confirmar
                        </button>
                        )}
                    </div>
                </div>
                {assignedTeachers.length === 0 && isOpen && (
                    <div className="w-full bg-white border border-gray-500 justify-between items-center p-2 gap-2 flex flex-col rounded-lg overflow-auto border-dashed max-h-[40vh]">
                        <div>
                        <p className="ml-2 text-gray-400">
                            No hay profesores asignados
                        </p>
                        </div>
                    </div>
                )}
                {assignedTeachers.length > 0 && isOpen && (
                    <div className="w-full bg-white border border-gray-200 justify-between items-start p-2 gap-2 flex flex-col rounded-lg overflow-auto max-h-[40vh]">
                        {assignedTeachers.map((teacherAssigned) => (
                            <div className="w-full bg-white border-opacity-40 justify-between items-center inline-flex g-2 ">
                                <div>
                                    <span className="ml-2">{teacherAssigned.user.email}</span>
                                </div>
                                <div>
                                    {loadingDelete && idDelete === teacherAssigned.id ? (
                                        <img
                                        src={spinner}
                                        alt="spinner"
                                        className="bg-white"
                                        width="30px"
                                        height="30px"
                                        />
                                    ) : (
                                        <button
                                        onClick={(e) => handleDeleteTeacher(e, teacherAssigned.id)}
                                        type="button"
                                        className=" text-black text-sm"
                                        >
                                        <RiDeleteBin6Line
                                            size={20}
                                            color="red"
                                            className="opacity-50 hover:opacity-100"
                                        />
                                        </button>
                                    )}
                                </div>
                                
                            </div>

                            


                        ))}
                    </div>
                )}


            </div>
            






        </div>


    );




    


}
import React from 'react';
import styled from 'styled-components';

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const CoverVideo = ({ gifSrc }) => {
  return (
    <VideoContainer>
      <StyledImage src={gifSrc} alt="Cover" />
    </VideoContainer>
  );
};

export default CoverVideo;

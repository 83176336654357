import React from 'react';

function Categoria({ icono, titulo, onClick, activa }) {
    const categoria = titulo.toLowerCase().replace(/ /g, '-');

    return (
        <div 
            className={`categoria ${activa ? 'activa' : ''}`} 
            data-categoria={categoria} 
            onClick={onClick}
        >
            {icono}
            <p>{titulo}</p>
        </div>
    );
}

export default Categoria;
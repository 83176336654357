import React, {useCallback, useState, useEffect } from "react"; 
import { useAuth0 } from "@auth0/auth0-react";
import { getProtected } from "../services/queries";
import Loading from "../components/Design/Loading";
import ContainerPage from "../components/BackOffice/layouts/ContainerPage";
import ResumeBackoffice from "../components/BackOffice/ResumenCardIndex";
import LineChart from "../components/BackOffice/LineChart";
import Navbar from "../components/Design/Navbar";
import Footer from "../components/Design/Footer";
export default function BackOffice() {
    const { getAccessTokenSilently } = useAuth0();
    const [usersPerMonthData, setUsersPerMonthData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const getDataUsers = useCallback(async () => {
        try{
            const accessToken = await getAccessTokenSilently();
            const usuarios = await getProtected("/users", accessToken);
            const usersPerMonth = usuarios.data.reduce((acc, usuario) => {
                const month = new Date(usuario.createdAt).getMonth();
                acc[month] = acc[month] ? acc[month] + 1 : 1;
                return acc;
            }, {});
            const months = [
              "ene",
              "feb",
              "mar",
              "abr",
              "may",
              "jun",
              "jul",
              "ago",
              "sep",
              "oct",
              "nov",
              "dic"
            ];
            const usersPerMonthArray = months.map((month) => {
                const index = months.indexOf(month);
                return usersPerMonth[index] || 0;
            });
            setUsersPerMonthData({
              labels: months,
              datasets: [
                {
                  label: "usuarios",
                  data: usersPerMonthArray,
                  backgroundColor: "rgba(255, 99, 132, 0.2)",
                  borderColor: "rgba(255, 99, 132, 1)",
                  pointBorderColor: "rgba(255, 99, 132, 1)",
                  borderWidth: 1,
                  fill: true,
                  tension: 0.4
                }
              ]
            });
        } catch (error) {
            setError(error);
        }
        setLoading(false);
    }, [getAccessTokenSilently]);

    useEffect(() => {
        getDataUsers();
    }, [getDataUsers]);
    if (error) return <div>{error.toString()}</div>;

    return (
        <>
        <Navbar />
        <ContainerPage>
        {loading ? (
            <Loading />
        ) : (
            <div className="flex flex-col justify-between space-x-2 space-y-2">
            {/* <ManteinanceMode /> */}
            <ResumeBackoffice />
            <div className="border-gray-200 shadow hover:bg-gray-100 w-full lg:h-[70vh] h-[50vh] m-auto p-4 border rounded-lg bg-white">
                {usersPerMonthData && (
                <LineChart
                    data={usersPerMonthData}
                    textTitle="Nuevos usuarios por mes"
                />
                )}
            </div>
            </div>
        )}
        </ContainerPage>
        <Footer />
        </>

    )
}
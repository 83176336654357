import React from "react";
import Navbar from "../components/Navbar";
function About() {
    return (
        <>
        <Navbar className="navbar" />
        <div>
            <h1>About us</h1>
        </div>
        </>
    )
    
}
export default About;
import React from 'react';
import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import Swal from "sweetalert2";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import { deleteProtected } from "../../services/queries";
import { useNavigate } from "react-router-dom";
import ChangeFormatDate from '../../components/Collections/ChangeFormatDate';
import "../dataTableStyles/dataTableReservedClass.css";

const DataTableReserved = ({ data }) => {
    const [selectedClass, setSelectedClass] = useState(null);
    const [show, setShow] = useState(false);
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    const handleSend = async (uuid) => {
        await Swal.fire({
            title: '¿Estás seguro?',
            text: '¿Quieres confirmar la cancelación de la clase reservada?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1A547C',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar!',
            cancelButtonText: 'No, cancelar!'
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete(uuid);
                navigate(`/`);
            }
        });
    }

    const handleDelete = async(uuid) => {
        try{
            const accessToken = await getAccessTokenSilently();
            const response = await deleteProtected(`/currentClass/${uuid}`, accessToken);
            if (response && response.error) {
                toast.error(response.error.message);
            } else {
                toast.success("Clase eliminada con éxito");
            }
        } catch(error) {
            toast.error(error.message);
        }
    }
    const handleShow = (item) => {
        setSelectedClass(item);
        setShow(true);
    };

    const handleClose = () => setShow(false);

    return (
        <div className="card-container">
            {data.map((item, index) => (
                <div key={index} className="class-card" onClick={() => handleShow(item)}>
                    <h3>{item.ramo} ({item.sigla})</h3>
                    <p>{ChangeFormatDate(item.classDate)} {item.classStart}</p>
                    <Button variant="primary">Ver más</Button>
                </div>
            ))}

            {/* Modal */}
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Detalles de la Clase</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedClass && (
                        <>
                            <p><strong>Ramo:</strong> {selectedClass.ramo}</p>
                            <p><strong>Sigla:</strong> {selectedClass.sigla}</p>
                            <p><strong>Fecha:</strong> {ChangeFormatDate(selectedClass.classDate)}</p>
                            <p><strong>Inicio:</strong> {selectedClass.classStart}</p>
                            <p><strong>Fin:</strong> {selectedClass.classEnd}</p>
                            <p><strong>Modalidad:</strong> {selectedClass.modality}</p>
                            <p><strong>Universidad:</strong> {selectedClass.university}</p>
                            <p><strong>Estado:</strong> {selectedClass.status}</p>
                            <p><strong>Profesor:</strong> {selectedClass.name}</p>
                            
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button variant="danger" onClick={handleSend}>
                        <FontAwesomeIcon icon={faTimes} /> Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default DataTableReserved;

import React from "react";
import tw, { styled } from "twin.macro";

export function SelectedTab(props) {
  const { text } = props;
  return (
    <StyleButton className="bg-white text-black" type="button">
      {text}
    </StyleButton>
  );
}

export function WaitingTab(props) {
  const { handleClick, text } = props;
  return (
    <StyleButton
      className="bg-slate-100 text-bg-100"
      type="button"
      onClick={handleClick}
    >
      {text}
    </StyleButton>
  );
}

const StyleButton = styled.button(tw`rounded-t-lg text-lg px-2 w-max h-10`);

import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Swal from "sweetalert2";
import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { postProtected } from '../../services/queries';
import '../styles/plans.css';

function PlansSeRemonta() {
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    const handleSend = async () => {
        await Swal.fire({
          title: '¡Consulta de plan enviado correctamente!',
          text: 'Gracias por consultar un plan, te contactaremos a la brevedad.',
          icon: 'success',
          confirmButtonText: 'Aceptar'
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(`/`);
          }
        });
    };

    const sendFormContact = async (values) => {
        try {
            const accessToken = await getAccessTokenSilently();
            const formContactPost = {
                nombre: values.nombre,
                email: values.email,
                mensaje: values.mensaje,
                ramo: values.ramo,
                nameRamo: values.nameRamo
            };
            await postProtected('/mailer/contact', formContactPost, accessToken);
        } catch (error) {
            console.log(error);
        }
    };

    const schemaPlans = yup.object().shape({
        nombre: yup.string()
            .min(4, 'El nombre es muy corto')
            .max(50, 'El nombre es muy largo')
            .required("El nombre es necesario"),
        email: yup.string().email().required("El correo es necesario"),
        mensaje: yup.string()
            .min(15, "El mensaje es muy corto")
            .max(500, "El mensaje es muy largo")
            .required("Se necesita un mensaje consultando el plan"),
        ramo: yup.string()
            .min(4, "Sigla muy corta")
            .max(50, "Sigla muy larga")
            .required("Se necesita la sigla del ramo o materia"),
        nameRamo: yup.string()
            .min(4, "Nombre del ramo muy corto")
            .max(50, "Nombre del ramo muy largo")
            .required("Se necesita el nombre del ramo o materia")
    });

    return (
        <div className="plans-container">
            <article className="plans-card">
                <h1>Consulta un plan</h1>
                
                <p className="plans-description">En esta sección podrás consultar un plan personalizado en <span className="text-highlight">SeRemonta</span>, te pediremos los siguientes datos que serán tratados de forma confidencial.</p>
                <p className="plans-description">Este es el primer contacto para llevar a cabo el plan, por lo tanto deberás detallar la duración del plan (mensual, semanal, etc) además de colocar sigla, ramo o materia que deseas junto con la distribución horaria que deseas y los periodos de tiempo.</p>
                <p className="plans-description">Una vez contestado y enviado el formulario nos pondremos en contacto contigo, adjuntando disponibilidad, profesor o profesores y el precio total del plan.</p>
            </article>

            <Formik
                validationSchema={schemaPlans}
                initialValues={{ nombre: '', email: '', mensaje: '', ramo: '', nameRamo: '' }}
                onSubmit={(values, { setSubmitting }) => {
                    sendFormContact(values);
                    setSubmitting(false);
                    handleSend();
                }}
            >
                {({ errors, touched }) => (
                    <Form className="form-container">
                        <fieldset className="plans-card">
                            <div className="mb-3">
                                <label htmlFor="nombreInput" className="form-label text-highlight">Nombre y apellido</label>
                                <Field type="text" className={`form-control ${errors.nombre && touched.nombre ? 'is-invalid' : ''}`} id="nombreInput" placeholder="Escribe tu nombre" name="nombre" />
                                <ErrorMessage name="nombre" component="div" className="invalid-feedback" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="correoInput" className="form-label text-highlight">Correo de contacto</label>
                                <Field type="email" className={`form-control ${errors.email && touched.email ? 'is-invalid' : ''}`} id="correoInput" placeholder="name@example.com" name="email" />
                                <ErrorMessage name="email" component="div" className="invalid-feedback" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="ramoInput" className="form-label text-highlight">Sigla ramo o materia</label>
                                <Field type="text" className={`form-control ${errors.ramo && touched.ramo ? 'is-invalid' : ''}`} id="ramoInput" placeholder="Coloca la sigla del ramo o materia" name="ramo" />
                                <ErrorMessage name="ramo" component="div" className="invalid-feedback" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="nameRamoInput" className="form-label text-highlight">Nombre del ramo o materia</label>
                                <Field type="text" className={`form-control ${errors.nameRamo && touched.nameRamo ? 'is-invalid' : ''}`} id="nameRamo" placeholder="Coloca el nombre del ramo o materia" name="nameRamo" />
                                <ErrorMessage name="nameRamo" component="div" className="invalid-feedback" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="mensajeTextarea" className="form-label text-highlight">Detalla las características del plan personalizado que deseas</label>
                                <Field as="textarea" className={`form-control ${errors.mensaje && touched.mensaje ? 'is-invalid' : ''}`} id="mensajeTextarea" placeholder="Máximo 500 caracteres" rows="3" name="mensaje" />
                                <ErrorMessage name="mensaje" component="div" className="invalid-feedback" />
                            </div>
                        </fieldset>
                        <button type="submit" className="btn-submit">Enviar</button>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default PlansSeRemonta;

import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import { AiOutlineSearch } from "react-icons/ai";
import swal from 'sweetalert2';
import { format } from "date-fns";
import Modal from "../../shared/modal";
import TableAssigment from "../editClass/tableAssigment";
import Pagination from "../../BackOffice/data/pagination";
import SectionClass from "../../class/editClass/sectionClass";
import SectionTeacher from "../../class/editClass/sectionTeacher";
import spinner from "../../../assets/gifs/spinner.gif";
import { patchProtected, getProtected, postProtected } from "../../../services/queries";
import { useNavigate } from "react-router-dom";
const itemsPerPage = 3; // Cantidad de asignaciones por página

export default function PaginatedAssigment({ assignments, handleDelete, reload}) {
    const { getAccessTokenSilently } = useAuth0();
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedAssignment, setSelectedAssignment] = useState(null);
    const [editAssigment, setEditAssigment] = useState(null);
    const [totalPages, setTotalPages] = useState(0);
    const [currentAssignments, setCurrentAssignments] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [loadingEdit, setLoadingEdit] = useState(false);
    const navigate = useNavigate();

    const formatDate = (value) => {
        const date = new Date(value);
        return format(date, "dd/MM/yyyy HH:mm:ss");
    };
    
    useEffect(() => {

        // Filtrar las asignaciones basadas en el término de búsqueda
        const filteredAssignments = assignments.filter((item) =>
            searchTerm.toLowerCase() === ""
                ? item
                : (item.name ? item.name.toLowerCase().includes(searchTerm.toLowerCase()) : false) ||
                (item.state ? item.state.toLowerCase().includes(searchTerm.toLowerCase()) : false)
            );
    
        // Manejar la paginación en base a los cambios en la data filtrada
        const indexOfLastAssignment = currentPage * itemsPerPage;
        const indexOfFirstAssignment = indexOfLastAssignment - itemsPerPage;
        const assignmentsSlice = filteredAssignments.slice(
          indexOfFirstAssignment,
          indexOfLastAssignment
        );
    
        setCurrentAssignments(assignmentsSlice);
        setTotalPages(Math.ceil(filteredAssignments.length / itemsPerPage));
        }, [assignments, currentPage, searchTerm]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleEdit = async (e, assignment) => {
        e.preventDefault();
        try{
            setLoadingEdit(true);
            setSelectedAssignment(assignment);
            setEditAssigment(assignment);
            window.assigmentEditModal.showModal();
            setLoadingEdit(false);
        } catch (err){
            toast.error(err.message);
        }
    }
    const getCurrentClassData = async (id) => {
        try {
            const accessToken = await getAccessTokenSilently();
            const response = await getProtected(`/currentClass/${id}`, accessToken);
            return response.data;
        } catch (error) {
            toast.error(error.message);
        }
    }

    const handleChangeAssigment = (e) => {
        e.preventDefault();
        setEditAssigment({
          ...editAssigment,
          [e.target.name]: e.target.value
        });
      };

    const editPatchForm = async (e) => {
        try{
            const accestoken = await getAccessTokenSilently();
            const body = {
                sigla: editAssigment.sigla,
                status: editAssigment.status,
                classStart: editAssigment.classStart,
                classEnd: editAssigment.classEnd,
                classDate: editAssigment.classDate
            }
            const responsePath = await patchProtected(
                `/currentClass/${editAssigment.id}`,
                body,
                accestoken);
            if (responsePath && responsePath.error) {
                toast.error(responsePath.error.message);
            }
            reload();
        } catch (err){
            toast.error(err.message);
        }
    }
    const getDataTeacher = async(id) => {
        try {
            const accessToken = await getAccessTokenSilently();
            const response = await getProtected(`/teachers/${id}`, accessToken)
            if (response && response.error) {
                toast.error(response.error.message);
            } else {
                return response.data;
            }
        } catch (error) {
            toast.error(error.message);
        }
    }
    const getUserInfo = async (id) => {
        try {
            const accessToken = await getAccessTokenSilently();
            const response = await getProtected(`/users/${id}`, accessToken);
            if (response && response.error) {
                toast.error(response.error.message);
            } else {
                return response.data;
            }
        } catch (error) {
            toast.error(error.message);
        }

    }

    const sendEmailToStudentChangeStatusToAssigned = async (e, edAssig) => {
        try {
            const dataTeacher = await getDataTeacher(edAssig.teacherId);
            const dataStudent = await getUserInfo(edAssig.studentId);
            const accessToken = await getAccessTokenSilently();
            const body = {
                "name": edAssig.name,
                "lastName": edAssig.lastName,
                "classDate": edAssig.classDate,
                "classStart": edAssig.classStart,
                "classEnd": edAssig.classEnd,
                "university": edAssig.university,
                "sigla": edAssig.sigla,
                "ramo": edAssig.ramo,
                "modality": edAssig.modality,
                "teacherName": dataTeacher.name,
                "price": dataTeacher.price,
                "email": dataStudent.email
            }
            const response = await postProtected(`/mailer/notification/assigned`, body, accessToken);
            if (response && response.error) {
                toast.error(response.error.message);
            } else {
                return response.data;
            }
        }
        catch (error) {
            toast.error(error.message);
        }
    }
    const sendEmailToStudentChangeStatusToPaid = async (e, edAssig) => {
        try {
            const dataTeacher = await getDataTeacher(edAssig.teacherId);
            const dataStudent = await getUserInfo(edAssig.studentId);
            const accessToken = await getAccessTokenSilently()
            const body = 
            {
                name: edAssig.name,
                lastName: edAssig.lastName,
                classDate: edAssig.classDate,
                classStart: edAssig.classStart,
                classEnd: edAssig.classEnd,
                university: edAssig.university,
                sigla: edAssig.sigla,
                ramo: edAssig.ramo,
                modality: edAssig.modality,
                teacherName: dataTeacher.name,
                price: dataTeacher.price,
                emailAdmin: "seremonta.cl@gmail.com",
                email: dataStudent.email,
                emailTeacher: dataTeacher.email,
                contactTeacher: dataTeacher.contact
            }
            const response = await postProtected(`/mailer/notification/paid`, body, accessToken);
            if (response && response.error) {
                toast.error(response.error.message);
            } else {
                return response.data;
            }

        } 
        catch(error){
            toast.error(error.message);
        }
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        await swal.fire({
            title: "¿Estás seguro?",
            text: "¡Al editar el estado se enviará un correo al estudiante!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await swal.fire({ text: "Se han realizado los cambios correctamente.", icon: "success" }).then(
                    async () => {
                        const resp = await getCurrentClassData(editAssigment.id);
                        if (resp.status != editAssigment.status) {
                            if (editAssigment.status === "Asignada"){
                                sendEmailToStudentChangeStatusToAssigned(e, editAssigment);
                                
                                
                            }
                            if (editAssigment.status === "Pagada"){
                                sendEmailToStudentChangeStatusToPaid(e, editAssigment);
                                
                            }
                        }

                        await editPatchForm();
                    }
                );
            } else {
                navigate(`/backoffice/clases`, { replace: true });
            }
        });

        
    }


    return (
        <div className="h-[60vh] flex flex-col">
            <div className="flex flex-row items-center w-full">
                <Form className="w-full p-2">
                    <div className="w-full inline-flex items-center gap-2 content-center text-base px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-400">
                        <AiOutlineSearch className="text-gray-500" size={20} />
                        <Form.Control
                        placeholder="Filtrar..."
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                            setCurrentPage(1);
                        }}
                        className="border-none focus:outline-none w-full"
                        />
                    </div>
                </Form>
            </div>
            
            <div className="flex-grow h-[50vh] overflow-auto no-scrollbar">

                <TableAssigment
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    currentAssignments={currentAssignments}
                />
            </div>
            <Modal
                id="assigmentEditModal"
                title="Editar asignación"
                submitText="Editar"
                handleSubmit={handleFormSubmit}
            >
                {selectedAssignment && (
                    <div className="flex flex-col gap-4 p-2">
                        <SectionClass
                            selected={editAssigment}
                            handleChange={handleChangeAssigment}
                        />
                        <SectionTeacher
                            selectedAssigment={selectedAssignment}
                        />
                        {loadingEdit ? (
                            <img
                                src={spinner}
                                alt="spinner"
                                className="bg-white"
                                width="30px"
                                height="30px"
                            />
                            ) : (
                            <button type="submit" className="btn mt-4 btn-primary">
                                Editar
                            </button>
                            )}
                    </div>
                )}
            </Modal>
            <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
            />
        </div>
    )
}
import React, { useState } from 'react';
import './../frequentCss/frequentQuestions.css';

function Pregunta({ pregunta, respuesta }) {
    const [activo, setActivo] = useState(false);

    const toggleRespuesta = () => {
        setActivo(!activo);
    };

    return (
        <div className={`contenedor-pregunta ${activo ? 'activo' : ''}`} onClick={toggleRespuesta}>
            <div className={`pregunta ${!activo ? 'inactivo' : ''}`}>
                {pregunta}
                <span className={`mas-menos ${activo ? 'blanco' : ''}`}>{activo ? '-' : '+'}</span>
            </div>
            {activo && <div className="respuesta-question">{respuesta}</div>}
        </div>
    );
}

export default Pregunta;

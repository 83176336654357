import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Form as FormikForm, Field, ErrorMessage } from 'formik';
import { Form, Row, Col } from 'react-bootstrap';
import { useAuth0 } from "@auth0/auth0-react";
import { Formik } from 'formik';
import * as yup from 'yup';
import { DateTimePicker, DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import '../styles/takeFormClass.css';
import Autosuggest from 'react-autosuggest';
import data from './universidades.json';
import Select from 'react-select';
import { postProtected, getProtected } from '../../services/queries';

const universities = data.universidades;
const today = new Date();
const maxDate = new Date();
maxDate.setDate(today.getDate() + 14);
// Función para sumar una hora a una cadena de tiempo
function sumarUnaHora(cadenaTiempo) {
  let [horaMinutos, amPm] = cadenaTiempo.split(' ');
  let [hora, minutos] = horaMinutos.split(':');
  hora = parseInt(hora, 10);
  // Convertir horas en formato PM a un reloj de 24 horas
  // if (amPm === 'pm' && hora !== 12) {
  //   hora += 12;
  // }

  // Sumar una hora
  hora = (hora + 1) % 24;

  // Convertir la hora de nuevo a formato AM/PM
  amPm = hora < 12 ? 'am' : 'pm';
  // hora = hora % 12 || 12;

  return `${hora}:${minutos} ${amPm}`;
}

function FormTakeClass() {
  const [selectedStartTime, setSelectedStartTime] = useState('');

  const [currSection, setCurrSection] = useState('#datos-personales');
  const [display, setDisplay] = useState(false);
  

  const [university, setUniversity] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  // const [currentView, setCurrentView] = useState('');

  const hourToIndex = (hour) => hours.indexOf(hour);

  const getEndTimes = () => {
    if (!selectedStartTime) return hours_fin;

    const startIndex = hourToIndex(selectedStartTime);
    const maxDuration = 3; // 5 time slots which is equivalent to 5 hours
    let hour_st = hours_fin[startIndex]
    let hora_sumada = sumarUnaHora(hours[startIndex])
    
    var hours_final = [];
    var actual_count = hour_st
    
    hours_final.push(hora_sumada)
    for (let index = 0; index < (maxDuration-1); index++) {
      hora_sumada = sumarUnaHora(hora_sumada)
      hours_final.push(hora_sumada)
      actual_count += 1;

    }
    
  
    
    return hours_final
    
  };
  const getActualUser = async () => {
    try{
      const accessToken = await getAccessTokenSilently();
      const { data } = await getProtected('/users/me', accessToken);
      return data;
      
    }
    catch(error){
      return false;
    }
  }
  const sendForm = async (values) => {
    
    try{
      const accessToken = await getAccessTokenSilently();
      let [hourStart, ampm] = values.startTime.split(' ');
      let [hourEnd, ampm2] = values.endTime.split(' ');
      let actualStudent = await getActualUser();
      const formToPost ={
        name: values.firstName,
        lastName: values.lastName,
        classDate: values.date,
        classStart: hourStart,
        classEnd: hourEnd,
        university: values.university,
        sigla: values.ramo,
        ramo: values.nombreRamo,
        modality: values.modalidad,
        place: values.state,
        studentId: actualStudent.id,
        status: "reservada"
      }
      const formToMailerBooking = {
        name: values.firstName,
        email: actualStudent.email,
        lastName: values.lastName,
        classDate: values.date,
        classStart: hourStart,
        classEnd: hourEnd,
        university: values.university,
        sigla: values.ramo,
        ramo: values.nombreRamo,
        modality: values.modalidad,
        place: values.state,
        studentId: actualStudent.id
      }
      
      const { data } = await postProtected('/currentClass', formToPost, accessToken);
      const { dataBooking } = await postProtected('/mailer', formToMailerBooking, accessToken);  //Enviar email (desactivado por mientras para evitar spam)
    
    }
    catch(error){
      console.log(error);
    }
  }

  const navigate = useNavigate();

  const handleSend = async () => {
    await Swal.fire({
      title: 'Clase reservada!',
      text: 'Gracias por reservar tu clase, te contactaremos a la brevedad!',
      icon: 'success',
      confirmButtonText: 'Aceptar!'
    }).then((result) => {
      if (result.isConfirmed) {
        navigate(`/`);
      }
    });
  }
  const removeAccents = str => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }
  const getSuggestions = value => {
    const inputValue = removeAccents(value.trim().toLowerCase());
  const inputLength = inputValue.length;

  return inputLength === 0 ? [] : universities.filter(univ =>
    removeAccents(univ.nombre.toLowerCase()).includes(inputValue) ||
    univ.acronimo.toLowerCase().includes(inputValue)
  ).slice(0, 2);
  };
  const onUniversityChange = (option) => {
    setUniversity(option ? option.value : '');
  };
  
  const universityOptions = universities.map(univ => ({
    value: univ.nombre,
    label: `${univ.nombre} (${univ.acronimo})`
  }));
  const hours = [
    '8:20 am',
    '9:40 am',
    '11:00 am',
    '12:20 pm',
    '14:50 pm',
    '16:10 pm',
    '17:30 pm',
    '18:50 pm',
    '20:10 pm',
  ];
  const hours_fin = [
    '9:20 am',
    '10:40 am',
    '12:00 am',
    '13:20 pm',
    '15:50 pm',
    '17:10 pm',
    '18:30 pm',
    '19:50 pm',
    '21:10 pm',
  ];

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .min(2, 'Nombre invalido')
      .max(50, 'Nombre muy largo')
      .required("El nombre es requerido"),
    lastName: yup
      .string()
      .min(4, 'Nombre invalido')
      .max(30, 'Nombre muy largo')
      .required("El apellido es requerido"),
    // day: yup.string().required("Seleccione el día de la clase"),
    startTime: yup.string().required("Seleccione la hora de inicio"),
    endTime: yup.string().required("Seleccione la hora de termino"),
    university: yup.string()
    .notOneOf([''], "Escribe el nombre de la universidad")
    .required("Seleccione la universidad"),
    ramo: yup.string()
    .min(4, "sigla del ramo invalida")
    .max(10, "sigla del ramo invalida")
    .required("Ingrese la sigla del ramo"),
    nombreRamo: yup.string()
    .min(4, "Nombre del ramo invalido")
    .max(20, "Nombre del ramo invalido")
    .required("Ingrese el nombre del ramo"),
    modalidad: yup.string()
    .notOneOf([''], "Selecciona...")
    .required("Se debe seleccionar la modalidad de la clase"),
    state: yup.string()
    .when('modalidad', (modalidad, schema) => {
      return modalidad === 'Presencial'
        ? schema.required('Es necesario seleccionar un estado')
        : schema
    }),
    
    
  
    date: yup.date().required('Debe colocar fecha de reserva'),
    terms: yup.bool().required().oneOf([true], 'Terms must be accepted'),
  });




  return (
    <>
    
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Formik
        validationSchema={schema}
        initialValues={{
          firstName: '',
          lastName: '',
          // day:'',
          startTime: '',
          endTime: '',
          university: '',
          ramo: '',
          nombreRamo: '',
          state: '',
          modalidad: '',
          date: null,
          terms: false,
        }}
        onSubmit={(values) => {
          sendForm(values);
          handleSend();
         }}
        validator={() => ({})}
      >
        {({
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          touched,
          errors,
        }) => (
          <FormikForm>
            <Row className="tc-section" id='datos-personales'>
                <header className='tc-title'>¡Agenda tu próxima clase!</header>
                <h2>Datos personales</h2>
                <p className='information'>Ingresa tu(s) nombre(s) y Apellidos 😊</p>
                <Form.Group className='tc-group' controlId="validationFormik01">
                  <Form.Label> Nombres </Form.Label>
                  <Field
                    name="firstName"
                    as={Form.Control}
                    type="text"
                    isInvalid={touched.firstName && !!errors.firstName}

                    
                  />
                  <ErrorMessage name="firstName" component="div" className="invalid-feedback"/>
                  
                </Form.Group>
                
                <Form.Group className='tc-group' controlId="validationFormik02">
                  <Form.Label>Apellidos</Form.Label>
                  <Field
                    name="lastName"
                    as={Form.Control}
                    type="text"
                    isInvalid={touched.lastName && !!errors.lastName}
                    
                  />
                  <ErrorMessage name="lastName" component="div" className="invalid-feedback" />
                </Form.Group>
            </Row>

            <Row className="tc-section" id='datos-reserva'>
              <h2>Reserva</h2> 
              <p className='information'>¡Agenda tu clase particular!</p>
              <Form.Group className='tc-group' controlId="validationFormikDate">
                <Form.Label>Fecha y Hora de Reserva</Form.Label>
                <Field name="date">
                  {({ field, form }) => (
                    <DatePicker
                      {...field}
                      selected={(field.value && new Date(field.value)) || null}
                      dateFormat="dd/MM/yyyy"
                      onChange={value => form.setFieldValue(field.name, value)}
                      minDate={today}
                      maxDate={maxDate}
                    />
                  )}
                </Field>
                <ErrorMessage name="date" component="div" />
              </Form.Group>

              <Form.Group className='tc-group'>
                <Form.Label>Hora de inicio</Form.Label>
                <Field as="select" name="startTime" className={`form-select ${touched.startTime && errors.startTime ? 'is-invalid' : ''}`}
                  onChange={(e) => {
                    handleChange(e);
                    setSelectedStartTime(e.target.value);
                  }}
                >
                  {hours.map((hour) => (
                    <option key={hour} value={hour}>
                      {hour}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="startTime" component="div" className="invalid-feedback" />
              </Form.Group>
              

              <Form.Group className='tc-group'>
                <Form.Label>Hora de finalización</Form.Label>
                <Field as="select" name="endTime" className={`form-select ${touched.endTime && errors.endTime ? 'is-invalid' : ''}`}>
                  {getEndTimes().map((hour) => (
                    <option key={hour} value={hour}>
                      {hour}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="endTime" component="div" className="invalid-feedback" />
              </Form.Group>
            </Row>
            

            <Row className="tc-section" id='datos-clase'>
              <h2>Datos de la clase</h2>
              <p className='information'>Ingresa todos los datos relacionados a la clase que necesitas</p>
              <Form.Group className='tc-group'>
                <Form.Label>Universidad</Form.Label>
                
                <Field
                  name='university'
                  as={Select}
                  placeholder='Escribe el nombre de la universidad'
                  options={universityOptions}
                  getOptionValue={option => option.value}
                  getOptionLabel={option => option.label}
                  onChange={option => {
                    setFieldValue('university', option ? option.value : '');
                    onUniversityChange(option);
                  }}
                  value={values.university ? universityOptions.find(option => option.value === values.university) : ''}
                />
                <ErrorMessage name="university" component="div" className="invalid-feedback" />
                
              </Form.Group>

              <Form.Group className='tc-group'>
                <Form.Label>Ramo(Sigla)</Form.Label>
                <Field
                  name="ramo"
                  as={Form.Control}
                  type="text"
                  isInvalid={touched.ramo && !!errors.ramo}
                />
                <ErrorMessage name="ramo" component="div" className="invalid-feedback" />
              </Form.Group>

              <Form.Group className='tc-group'>
                <Form.Label>Nombre del Ramo</Form.Label>
                <Field
                  name="nombreRamo"
                  as={Form.Control}
                  type="text"
                  isInvalid={touched.ramo && !!errors.ramo}
                />
                <ErrorMessage name="nombreRamo" component="div" className="invalid-feedback" />
              </Form.Group>

              <Form.Group className='tc-group'>
                <Form.Label>Modalidad de clase</Form.Label>
                <Field
                  as="select"
                  name="modalidad"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`form-control ${touched.modalidad && !!errors.modalidad ? 'is-invalid' : ''}`}
                >
                  <option value="">Selecciona...</option>
                  <option value="Online">Online</option>
                  <option value="Presencial">Presencial</option>
                </Field>
                <ErrorMessage name="modalidad" component="div" className="invalid-feedback" />
              </Form.Group>
              
              {values.modalidad === 'Presencial' && (
              <Form.Group className='tc-group' controlId="validationFormik04">
                <Form.Label>Dirección o campus</Form.Label>
                <Field
                  name="state"
                  as={Form.Control}
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.state && !!errors.state}
                />
                <ErrorMessage name="state" component="div" className="invalid-feedback" />
              </Form.Group>
              )}
            </Row>


            <Row className="tc-section" id='datos-fin'>
              <h2>Confirmar clase</h2>
              <p className='information'>Por último, acepta los terminos y condiciones de SeRemonta para poder pedir tu clase</p>
              <Form.Group className="tc-group__checkbox" id='terminos-y-condiciones'>
                <Field
                  name="terms"
                  as={Form.Check}
                  type="checkbox"
                  label="Aceptar términos y condiciones de SeRemonta"
                  isInvalid={touched.terms && !!errors.terms}
                />
                <ErrorMessage name="terms" component="div" className="invalid-feedback" />
              </Form.Group>
              <Button type="submit">Pedir clase</Button>
            </Row>
            
          </FormikForm>
        )}
      </Formik>
    </LocalizationProvider>


    {<TakeClassBtns currSection={currSection} setCurrSection={setCurrSection} display={display}/>}
    </>
  );
}

const TakeClassBtns = ({currSection, setCurrSection, display}) => {
  let [currIndex, setCurrIndex] = useState(0);
  const sections = [
    '#datos-personales',
    '#datos-reserva',
    '#datos-clase',
    '#datos-fin'
  ]

  

  const handlePrev = () => {
    if (currIndex > 0) {
      // window.location.href = sections[currIndex - 1];
      setCurrIndex(currIndex - 1);

      
    } else {
      // window.location.href = '#datos-personales';
      setCurrIndex(0);
    }
  }

  const handleNext = () => {
    if (currIndex < sections.length - 1) {
      // window.location.href = sections[currIndex + 1];
      setCurrIndex(currIndex + 1);
    } else {
      // window.location.href = '#datos-fin';
      setCurrIndex(sections.length - 1);
    }
  }
  useEffect(()=> {
    setCurrSection(sections[currIndex]);
    window.location.href = sections[currIndex];
  }, [currIndex, sections, setCurrSection]);



  return (
    
    <div className='curr-sections-btns' style={{display: display}}>
      <button onClick={handlePrev}>Atrás</button>
      <button onClick={handleNext}>Adelante</button>
    </div>
  );
}


export default FormTakeClass;
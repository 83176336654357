import './frequentCss/frequentQuestions.css';
import React from 'react';
import { useState } from 'react';
import Navbar from '../Design/Navbar';
import Footer from '../../components/Design/Footer';
import Categoria from './componentQuestions/category';
import Pregunta from './componentQuestions/question';
import categorias from './categoryFrequentQuestions.json';
import preguntas from './questionsFrequentQuestions.json';

function FrequentQuestions() {
    const [categoriaActiva, setCategoriaActiva] = useState(null);
    const [preguntasActivas, setPreguntasActivas] = useState({});

    const handleCategoriaClick = (categoria) => {
        setCategoriaActiva(categoria);
    };
    const handlePreguntaClick = (pregunta) => {
        setPreguntasActivas(prev => ({ ...prev, [pregunta]: !prev[pregunta] }));
    };

    return (
        <>
            <Navbar />
            <div className="FrequentQuestions" id="topQuestions">
                <h1 className="titulo">
                    PREGUNTAS FRECUENTES
                </h1>
                <main>
                    <div className="categorias" id="categorias">
                        {categorias.map((categoria) => (
                            <Categoria 
                                key={categoria.titulo}
                                icono={categoria.icono} 
                                titulo={categoria.titulo}
                                onClick={() => handleCategoriaClick(categoria.titulo.toLowerCase())}
                                activa={categoriaActiva === categoria.titulo.toLowerCase()} 
                            />
                        ))}
                    </div>
                    <div className="preguntas" id="preguntas">
                        {preguntas.filter(pregunta => pregunta.categoria === categoriaActiva).map((pregunta) => (
                            <Pregunta
                                key={pregunta.pregunta}
                                pregunta={pregunta.pregunta}
                                respuesta={pregunta.respuesta}
                                onClick={() => handlePreguntaClick(pregunta.pregunta)}
                                activo={preguntasActivas[pregunta.pregunta]}
                            />
                        ))}
                    </div>
                </main>
            </div>
            <Footer />
        </>
    )
}

export default FrequentQuestions;

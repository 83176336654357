import React from "react";

export default function AmountCardEntity({ title, amount, icon, description }) {
  return (
    <div className="flex-1 w-full items-center p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100">
      <div className="inline-flex">
        {title && (
          <div className="flex items-center">
            <span className="text-xl font-semibold text-gray-600">{title}</span>
          </div>
        )}
        {amount && (
          <div className="flex items-center">
            <span className="text-xl font-semibold text-gray-600">
              {amount}
            </span>
          </div>
        )}
        {icon && (
          <div>
            <div className="flex items-center justify-between mt-1 ml-3">
              <span className="text-xl font-semibold text-gray-600">
                {icon}
              </span>
            </div>
          </div>
        )}
      </div>
      <p className="2xl:text-xl xl:text-lg sm:text-sm">{description}</p>
    </div>
  );
}
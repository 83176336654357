import React, { useRef } from "react";

export default function MoreInfo({ id, children, className = "" }) {
  const dialogRef = useRef(null);

  const handleClose = () => {
    if (dialogRef.current) {
      dialogRef.current.close();
    }
  };

  return (
    <dialog id={id} className={`modal-box ${className}`} ref={dialogRef}>
      <button
        type="button"
        htmlFor={id}
        className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
        onClick={handleClose}
      >
        ✕
      </button>
      {children}
    </dialog>
  );
}
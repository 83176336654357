import React from "react";
import { useSelector } from "react-redux";
import SideNavbarDashboard from "./SideNavbar";
import Menus from "../../../assets/data/backofficemenu";

export default function ContainerPage({ children }) {
  const user = useSelector((state) => state.user);

  return (
    <div className="bg-slate-100 h-[100%] min-h-[100vh] w-[100%] overflow-hidden">
      {user.isAdmin && (
        <div className="flex flex-row w-full">
          <SideNavbarDashboard title="Menu Administrador" data={Menus} />
          <div className="p-7 m-[2%] w-full max-h-[100vh] overflow-auto no-scrollbar justify-between space-y-1 space-x-1">
            {children}
          </div>
        </div>
      )}
      {!user.isAdmin && (
        <div className="border-gray-200 shadow hover:bg-gray-100 w-full m-auto p-4 border rounded-lg bg-white">
          <h1>No tienes los permisos para esta acceder a página</h1>
        </div>
      )}
    </div>
  );
}
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { AiOutlineUser, AiFillCar } from "react-icons/ai";
import { FaWarehouse } from "react-icons/fa";
import AmountCardEntity from "./AmountCardEntity";
import { getProtected } from "../../services/queries";




export default function ResumeClassBackoffice(){
    const { getAccessTokenSilently } = useAuth0();
    const [resumeAmount, setResumeAmount] = useState([]);
    const [top3, setTop3] = useState([]);
    const [resumeTop3, setResumeTop3] = useState([]);
    useEffect(() => {
        const getData = async () => {
            try{
                const accessToken = await getAccessTokenSilently();
                const classesResponse = await getProtected("/currentClass", accessToken);
                const classLastWeek = await getProtected("/currentClass/lastweek", accessToken);
                const top3 = await getProtected("/currentClass/top3", accessToken);
                setTop3(top3)
                setResumeAmount([
                    {
                        title: "total de clases",
                        amount: classesResponse.data.length,
                        icon: (
                            <FaWarehouse className="text-sh-darkblue w-full h-full fill-current" />
                        ),
                        description:
                            "Clases totales pedidas historicamente"
                    },
                    {
                        title: "Clases semana actual",
                        amount: classLastWeek.data.length,//,
                        icon: (
                            <FaWarehouse className="text-sh-darkblue w-full h-full fill-current" />
                        ),
                        description:
                            "Clases pedidas los últimos 7 dias"
                    },
                    {
                        title: "Ingresos",
                        amount: 10,//,
                        icon: (
                            <FaWarehouse className="text-sh-darkblue w-full h-full fill-current" />
                        ),
                        description:
                            "Ingresos por comision del mes seleccionado"
                    },
                ])
                let newResumeTop3 = [];
                for (let i = 0; i < top3.data.length; i++) {
                    const element = top3.data[i];
                    newResumeTop3.push({
                        title: element.ramo,
                        amount: element.count,
                        icon: (
                            <FaWarehouse className="text-sh-darkblue w-full h-full fill-current" />
                        ),
                        description:
                            `Ramo en la posicion numero: ${i+1} de los más solicitados en SeRemonta`
                    });
                }
                setResumeTop3(newResumeTop3.slice(0, 3));
            }
            //GET CLLASS


            catch(error){
                console.log(error)
            }
        };
        getData();




    }, [getAccessTokenSilently]);
    return (
      <>
        <div className="flex flex-col w-full m-auto lg:flex-row justify-between space-x-0.5 space-y-0.5">
          {resumeAmount.map((item) => (
            <AmountCardEntity
              key={item.title}
              // title={item.title}
              amount={item.amount}
              icon={item.icon}
              description={item.description}
            />
          ))}
        </div>
        <div className="flex flex-row space-x-0.5">
            {resumeTop3 && resumeTop3.length > 0 && (
            resumeTop3.map((ramo, index) => (
              <AmountCardEntity
                key={index}
                title={ramo.title}
                amount={ramo.amount}
                icon={<FaWarehouse className="text-sh-darkblue w-full h-full fill-current" />}
                description={`${ramo.description}`}
              />
            )))}
      </div>
      </>
      );
}
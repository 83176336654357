import React from 'react';
import { useAuth0} from '@auth0/auth0-react';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import Navbar from '../Design/Navbar';
import Footer from '../../components/Design/Footer';
import TakeFormClass from '../../components/inc/takeFormClass';
import swal from 'sweetalert2';
import '../styles/TakeClass.css';
function TakeClass() {
  const { setTakeClassStatus, takeClassStatus } = useState(true); // MIENTRAS SE PIDEN CLASES POR WSP
  const { user, isAuthenticated, isLoading } = useAuth0();
  
  if (isLoading) {
    return <div>Loading ...</div>;
  }
  const handleWhatsAppRedirect = async () => {
    const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
  
    for (let i = 3; i > 0; i--) {
      swal.fire({
        title: `Estamos redirigiendo a WhatsApp para que puedas tomar clases en ${i}...`,
        text: 'Toma de clases por la página: EN MANTENCIÓN',
        icon: 'success',
        showConfirmButton: false,
      });
      await sleep(1000);
    }
  
    await sleep(1000); // Añadir un segundo más de retraso
  
    window.location.href = 'https://wa.me/56935737202?text=Hola%20buenas,%20quiero%20agendar%20una%20clase%20particular%20de:%0A%0A*Nombre%20y%20sigla%20del%20ramo*:%20%0A%0A*Nombre%20de%20la%20carrera*:%20%0A%0A*Universidad*:%0A%0AQuiero%20saber%20si%20tienen%20disponibilidad,%20muchas%20gracias'; // Reemplaza con tu número de teléfono y mensaje
  };

  if (takeClassStatus === true) {
    handleWhatsAppRedirect();
    return <Navigate to="/" />;
  }
  if (!isAuthenticated) {
    handleWhatsAppRedirect();
    return <Navigate to="/" />;
  }

  // if (!isAuthenticated) {
  //   swal.fire({
  //     title: 'Debes iniciar sesión',
  //     text: 'No Puedes tomar clases si no estas logueado',
  //     icon: 'warning',
  //   });
  //   return <Navigate to="/" />;
  // }
  
  return (
    <div>
      {isAuthenticated ? (
        <div className='takeclass-body'>
          <Navbar />

          <div className="take-class-container">
            <TakeFormClass></TakeFormClass>
          </div>

          <Footer />
        </div>
      ) : (
        
        //else
        <div className='takeclass-body'>
          <button onClick={handleWhatsAppRedirect}>Tomar Clase</button>
        </div>
      )}
    </div>
  );
}
export default TakeClass;
